import React, { useState, useEffect } from 'react';
import { format, subDays, isBefore, isAfter } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/ui/Header/HeaderHome';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { Links } from '../../components/links';
import { useNavigate, useSearchParams } from 'react-router-dom';

export async function sendAnalyticsDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.sendAnalyticsData,
    dispatch,
    `/user/analytics/send/data/`,
    'post',
    '',
    form,
    true
  )();
}

export async function analyticsDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.analyticsData,
    dispatch,
    `/user/analytics/data/`,
    'get',
    '',
    form,
    true
  )();
}

const AnalyticsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const [errorResponse, setErrorResponse] = useState<string>('');

  const [listOfUser, setListOfUser] = useState([]);
  const [countOfCanceledOrder, setCountOfCanceledOrder] = useState(0);

  const [selectedEmployee, setSelectedEmployee] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>(
    format(subDays(new Date(), 7), 'yyyy-MM-dd') // Количество дней по дефолту 7
  );
  const [dateTo, setDateTo] = useState<string>(
    format(new Date(), 'yyyy-MM-dd')
  );
  //   const [revenueData, setRevenueData] = useState(
  //     Array.from({ length: 7 }, (_, index) => ({
  //       date: format(subDays(new Date(dateTo), 6 - index), 'yyyy-MM-dd'),
  //       revenue: Math.floor(Math.random() * 500 + 100), // Случайные данные
  //     }))
  //   );
  const [graphicData, setGraphicData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  const analyticsDataStore = useSelector(
    (state: any) => state.analyticsDataStore
  );
  const sendAnalyticsDataStore = useSelector(
    (state: any) => state.sendAnalyticsDataStore
  );
  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    analyticsDataFunc(dispatch, {});
  }, []);

  useEffect(() => {
    // console.log('selectedEmployee', selectedEmployee);
    if (selectedEmployee !== '') {
      sendAnalyticsDataFunc(dispatch, {
        employee: selectedEmployee,
        date_from: dateFrom,
        date_to: dateTo,
      });
    }
  }, [selectedEmployee, dateFrom, dateTo]);

  useEffect(() => {
    // console.log('analyticsDataStore', analyticsDataStore);
    if (analyticsDataStore) {
      if (analyticsDataStore.data) {
        setListOfUser(analyticsDataStore.data.response);
      } else if (analyticsDataStore.error) {
        setError(analyticsDataStore.error);
      }
    }
  }, [analyticsDataStore]);

  useEffect(() => {
    // console.log('sendAnalyticsDataStore', sendAnalyticsDataStore);
    if (sendAnalyticsDataStore) {
      if (sendAnalyticsDataStore.data) {
        setCountOfCanceledOrder(
          sendAnalyticsDataStore.data.response.count_of_canceled_order
        );
        setGraphicData(sendAnalyticsDataStore.data.response.graphic_data);
        setRevenueData(sendAnalyticsDataStore.data.response.data);
      }
    }
  }, [sendAnalyticsDataStore]);

  //   useEffect(() => {
  //     console.log('revenueData', revenueData);
  //   }, [revenueData]);

  const handleDateFromChange = (value: string) => {
    if (!isAfter(new Date(value), new Date(dateTo))) {
      setDateFrom(value);
    }
  };

  const handleDateToChange = (value: string) => {
    if (!isBefore(new Date(value), new Date(dateFrom))) {
      setDateTo(value);
    }
  };

  useEffect(() => {
    // Пересчитать данные для графика при изменении дат
    const days = Math.abs(
      (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) /
        (1000 * 60 * 60 * 24)
    );
    // setRevenueData(
    //   Array.from({ length: days + 1 }, (_, index) => ({
    //     date: format(subDays(new Date(dateTo), days - index), 'yyyy-MM-dd'),
    //     revenue: Math.floor(Math.random() * 500 + 100), // Случайные данные
    //   }))
    // );
  }, [dateFrom, dateTo]);

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(analyticsDataStore && analyticsDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Перейти на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[120px] pb-[60px] px-4 space-y-6">
            <div>
              <select
                className="mb-4 p-2 border w-[300px] border-gray-300 rounded"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                <option value="" disabled>
                  Выберите сотрудника
                </option>
                {listOfUser.map((employee: any) => (
                  <option key={employee.username} value={employee.username}>
                    {employee.shop === ''
                      ? `${employee.username}`
                      : `${employee.username} (${employee.shop})`}
                  </option>
                ))}
              </select>

              <div className="flex items-center gap-4">
                <input
                  type="date"
                  className="p-2 border border-gray-300 rounded"
                  value={dateFrom}
                  onChange={(e) => handleDateFromChange(e.target.value)}
                />

                <div>-</div>

                <input
                  type="date"
                  className="p-2 border border-gray-300 rounded"
                  value={dateTo}
                  onChange={(e) => handleDateToChange(e.target.value)}
                />
              </div>
            </div>

            {graphicData && graphicData.length == 0 ? (
              <div>
                {errorResponse === '' ? 'Данные не найдены' : errorResponse}
              </div>
            ) : (
              <div>
                <div className="">
                  <h2 className="text-lg font-bold mb-4">График выручки</h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={graphicData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="revenue"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                <div>
                  <h2 className="text-lg font-bold mb-4">Данные в таблице</h2>
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2">Дата</th>
                        <th className="border border-gray-300 p-2">
                          Номер разбора
                        </th>
                        <th className="border border-gray-300 p-2">Выручка</th>
                      </tr>
                    </thead>
                    <tbody>
                      {revenueData.map((item: any, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            {item.date}
                          </td>
                          <td className="border border-gray-300 p-2">
                            #{item.revenue.order_numeration}
                          </td>
                          <td className="border border-gray-300 p-2">
                            {item.revenue.revenue} ₸
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
};

export default AnalyticsPage;
