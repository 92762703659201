import React, { useState } from 'react';

const TooltipModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [textType, setTextType] = useState('position');

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="relative">
      {/* Кнопка "?" */}
      <button
        onClick={handleOpen}
        className="bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-blue-600 focus:outline-none"
        aria-label="Help"
      >
        ?
      </button>

      {/* Модальное окно */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-3/4 max-w-md">
            <h2 className="text-lg font-bold mb-4">Подсказка</h2>

            <div className="mb-4 w-full flex justify-between items-center bg-gray-100 rounded-[10px]">
              <div
                className={`text-center w-1/2 p-2 cursor-pointer ${textType === 'position' && 'bg-gray-300 rounded-[10px]'}`}
                onClick={() => {
                  setTextType('position');
                }}
              >
                Позиции
              </div>
              <div
                className={`text-center w-1/2 p-2 cursor-pointer ${textType === 'album' && 'bg-gray-300 rounded-[10px]'}`}
                onClick={() => {
                  setTextType('album');
                }}
              >
                Альбомы
              </div>
            </div>

            <div className="mb-4 text-gray-700">
              {textType === 'position' && (
                <div>
                  <div className="mb-2">Позиции</div>
                  <div className="bg-gray-100 p-2 mb-4">
                    1. Феликс оддинари(2), Чан 5 стар(4), Феликс рокстар(6) -
                    2538тг/564 руб. @Sleeabo
                    <br />
                    <br />
                    2. Чан максидент(1), Чан кат фото со 2й годовщины с дебюта в
                    яп.(3), Джисон 5 стар(5) - 3038тг/675 руб. @ATHENA_EUM
                    <br />
                    <br />
                    ...
                  </div>
                </div>
              )}

              {textType === 'album' && (
                <div>
                  <div className="mb-2">Альбом</div>
                  <div className="bg-gray-100 p-2 mb-4">
                    -&gt;стандарт
                    <br />
                    <br />
                    1. @example1 (бесплатная дс)
                    <br />
                    2. @example2
                    <br />
                    <br />
                    -&gt;аккордеон
                    <br />
                    <br />
                    1. @example3 (бесплатная дс) <br />
                    2. @example4
                    <br />
                    <br />
                    -&gt;версия1
                    <br />
                    <br />
                    -&gt;платформ - 0.9 <br />
                    <br />
                    1. @example5 <br />
                    2. @example6
                    <br />
                    <br />
                    ...
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={handleClose}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipModal;
