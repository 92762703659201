import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from '../../components/ui/LoadingScreen';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import ordersData from '../home/orders.json';
import Header from '../../components/ui/Header/HeaderHome';
import Footer from '../../components/ui/Footer/FooterHome';
import FeedbackIcon from '../../components/svg/feedback-icon';
import PromotionIcon from '../../components/svg/promotion-icon';
import ChatIcon from '../../components/svg/chat-icon';
import CouponIcon from '../../components/svg/coupon-icon';
import { Links } from '../../components/links';
import ImageTable from '../../components/ui/OrderTracking/ImageTable';

export async function allOrderTableDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.allOrderTableData,
    dispatch,
    `/user/all/order/data/`,
    'post',
    '',
    form,
    true
  )();
}

export default function Page() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = utils.useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get('search') || ''
  ); // Управляемое поле
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState<any>(0);

  const [orders, setOrders] = useState<any>([]);

  const [error, setError] = useState<any>('');

  const allOrderTableDataStore = useSelector(
    (state: any) => state.allOrderTableDataStore
  );
  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    // console.log('allOrderTableDataStore', allOrderTableDataStore);
    if (allOrderTableDataStore) {
      if (allOrderTableDataStore.data) {
        const response = allOrderTableDataStore.data.response;

        setOrders(response.data);
        setCount(response.count);
        // setCurrentPage(response.current_page);
        setTotalPages(response.num_pages);
      } else if (allOrderTableDataStore.error) {
        setError(allOrderTableDataStore.error);
      }
    }
  }, [allOrderTableDataStore]);

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams({ page: '1', search: searchQuery });
    }
  }, [searchParams, setSearchParams, searchQuery]);

  const handlePageChange = (page: number) => {
    setSearchParams({ page: page.toString() });
  };

  useEffect(() => {
    allOrderTableDataFunc(dispatch, {
      page: currentPage,
      searchQuery: searchQuery,
    });
    //   }, [currentPage, searchQuery]);
  }, [searchParams]);

  // Обработчик поиска
  const handleSearch = () => {
    // Если поле пустое, сохраняем только параметр страницы
    if (!searchQuery) {
      setSearchParams({ page: '1' });
    } else {
      if (/^[1-9]\d*$/.test(searchQuery) || searchQuery === '') {
        setSearchParams({ page: '1', search: searchQuery });
      }
    }
  };

  // Обработчик ввода: только положительные числа > 0
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Только положительные целые числа > 0
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setSearchQuery(value); // Обновляем состояние
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Считаем количество строк для каждого `order_numeration`
  const groupedOrders = orders.reduce((acc: any, order: any) => {
    acc[order.order_numeration] = (acc[order.order_numeration] || 0) + 1;
    return acc;
  }, {});

  function OrderStatusFunc(orderStatus: string) {
    if (orderStatus === 'Выкуплен') {
      return (
        <div className="p-1 rounded-[5px] bg-orange-200 text-center">
          Выкуплен
        </div>
      );
    } else if (orderStatus === 'Отправлен к кор') {
      return (
        <div className="p-1 rounded-[5px] bg-yellow-200 text-center">
          Отправлен к кор
        </div>
      );
    } else if (orderStatus === 'Прибыл к кор') {
      return (
        <div className="p-1 rounded-[5px] bg-purple-200 text-center">
          Прибыл к кор
        </div>
      );
    } else if (orderStatus === 'Прибыл к Кз') {
      return (
        <div className="p-1 rounded-[5px] bg-green-200 text-center">
          Прибыл к Кз
        </div>
      );
    } else if (orderStatus === 'Отменен') {
      return (
        <div className="p-1 rounded-[5px] bg-red-200 text-center">Отменен</div>
      );
    }
  }

  // Статусы заказа: Выкуплен, Отправлен к кор, Прибыл к кор, Прибыл в Кз, Отменен

  // if (hitProductsMainPageStore && hitProductsMainPageStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={hitProductsMainPageStore.load} />
  //     </div>
  //   );
  // }

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(allOrderTableDataStore && allOrderTableDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Перейти на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[120px] pb-[60px] px-4">
            <div className="text-xl font-semibold mb-4">
              Таблица всех заказов
            </div>
            <div className="mb-4">
              <div className="mb-[2px]">
                Найденное количество разборов:{' '}
                <span className="font-bold">{count}</span>
              </div>

              {searchParams.has('search') &&
                searchParams.get('search') !== '' && (
                  <div>
                    Запрос поиска: «
                    <span className="font-bold">
                      {searchParams.get('search')}
                    </span>
                    »
                  </div>
                )}
            </div>

            {/* Форма поиска */}
            <div className="mb-4 flex">
              <input
                type="text"
                name="search"
                placeholder="Поиск по номеру разбора"
                value={searchQuery} // Привязываем значение
                onChange={handleInputChange} // Вызываем обработчик
                className="border p-2 rounded min-w-[150px] w-[150px]"
                onKeyDown={handleKeyPress}
              />
              <button
                type="button" // Кнопка теперь не форма
                onClick={handleSearch} // Вызов обработчика поиска
                className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Поиск
              </button>
              <button
                type="button" // Кнопка теперь не форма
                onClick={() => {
                  setSearchQuery('');
                  setSearchParams({ page: '1' });
                }} // Вызов обработчика поиска
                className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Очистить
              </button>
            </div>

            {/* <div className="h-screen overflow-x-auto"> */}
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse border border-gray-300 text-sm">
                {/* <thead className="sticky top-0 z-20"> */}
                <thead className="">
                  <tr className="bg-gray-200 text-left">
                    <th className="sticky left-[-2px] bg-gray-200 border border-gray-300 px-4 py-2 z-10">
                      Номер разбора
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Дата разбора
                    </th>
                    {[
                      'Название магазина',
                      'Страна разбора',
                      'Группа/Исполнитель',
                      'Изображения',
                      'Название позиции',
                      'Цена позиции в у.е.',
                      'Цена в тенге по курсу',
                      'Цена в тенге (без учетов)',
                      'Прибыль',
                      'Ник покупателя',
                      'Статус оплаты',
                      'Дата и время оплаты',
                      'Учеты',
                      'Итого по разбору',
                      'Зарплата администратора',
                      'Прибыль магазина',
                      'Курс тенге к у.е.',
                      'Курс тенге к у.е. магазина',
                      'Трек-код посылки',
                      'Статус разбора',
                      'Ник продавца',
                      'Статус выплаты зарплаты',
                      'Дата и время выплаты зарплаты',
                      'Переплата по разбору',
                      'Пост с оплатой кор',
                      'Пост с оплатой',
                      'Комментарий',
                      // '',
                    ].map((header) => (
                      <th
                        key={header}
                        className={`border border-gray-300 px-4 py-2`}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {orders.length === 0 ? (
                    <tr>
                      <td colSpan={28} className="">
                        Данные не найдены
                      </td>
                    </tr>
                  ) : (
                    orders.map((order: any, index: number) => {
                      // Если это первая строка для `order_numeration`, рендерим ячейку с `rowSpan`
                      const isFirstRow =
                        index === 0 ||
                        order.order_numeration !==
                          orders[index - 1].order_numeration;

                      return (
                        <tr
                          key={index}
                          // className={`border border-gray-300 ${
                          //   index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                          // }`}
                          className={`border border-gray-300`}
                        >
                          {isFirstRow && (
                            <td
                              className="sticky left-[-2px] border border-gray-300 bg-gray-100 text-center px-4 py-2 z-10"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              <div className="flex justify-center items-center">
                                <div className="flex space-x-[10px] items-center">
                                  {/* <button className="invisible">
                              <Icon
                                icon="mage:edit"
                                className="w-[24px] h-[24px]"
                              />
                            </button> */}
                                  <div className="">
                                    <div>{order.username}</div>
                                    <div>#{order.order_numeration}</div>
                                  </div>
                                  <button
                                    onClick={() => {
                                      utils.handleClickOnNewWindow(
                                        `https://adm.meloddy.kz/order/edit/${order.order_id}`
                                      );
                                      //   navigate(`/order/edit/${order.order_id}`);
                                    }}
                                  >
                                    <Icon
                                      icon="mage:edit"
                                      className="w-[24px] h-[24px]"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.order_date}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.shop_name}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[150px] min-w-[150px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.order_country}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.band_or_artist}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[300px] min-w-[300px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              <ImageTable images={order.list_of_image_path} />
                            </td>
                          )}
                          <td className="border border-gray-300 px-4 py-2 w-[150px] min-w-[150px]">
                            {order.item_title}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.item_price_in_conventional_units}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.item_price_in_tenge}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.item_price_in_tenge_without_surcharge}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.item_profit}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.buyer_nick}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <div
                              className={`font-medium ${order.payment_status === 'Оплачено' ? 'text-green-400' : 'text-red-400'}`}
                            >
                              {order.payment_status}
                            </div>
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {order.payment_date}
                          </td>

                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.surcharge}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.total_amount}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.admin_salary}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.shop_profit}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.exchange_rate}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.shop_exchange_rate}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.tracking_code}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {OrderStatusFunc(order.order_status)}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.seller_nick}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[125px] min-w-[125px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              <div
                                className={`font-medium ${order.salary_payment_status === 'Оплачено' ? 'text-green-400' : 'text-red-400'}`}
                              >
                                {order.salary_payment_status}
                              </div>
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[125px] min-w-[125px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.salary_payment_date}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[125px] min-w-[125px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.order_overpayment}
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              <button
                                onClick={() => {
                                  utils.handleClickOnNewWindow(
                                    order.payment_post_link_kor
                                  );
                                }}
                                className={`p-2 ${order.payment_post_link_kor ? 'bg-blue-500' : 'bg-gray-400'} rounded-[5px] text-white`}
                              >
                                Перейти
                              </button>
                            </td>
                          )}
                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              <button
                                onClick={() => {
                                  utils.handleClickOnNewWindow(
                                    order.payment_post_link
                                  );
                                }}
                                className={`p-2 ${order.payment_post_link ? 'bg-blue-500' : 'bg-gray-400'} rounded-[5px] text-white`}
                              >
                                Перейти
                              </button>
                            </td>
                          )}

                          {isFirstRow && (
                            <td
                              className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]"
                              rowSpan={groupedOrders[order.order_numeration]}
                            >
                              {order.comment}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            {/* Пагинация */}
            <div className="flex justify-center items-center mt-[40px] space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
                disabled={currentPage === 1}
              >
                Назад
              </button>

              <span className="px-4 py-2 text-gray-700">
                Страница {currentPage} из {totalPages}
              </span>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
                disabled={currentPage === totalPages}
              >
                Вперед
              </button>
            </div>
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
}
