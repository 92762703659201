import React, { useEffect, useState } from 'react';
import Header from '../../components/ui/Header/HeaderHome';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import * as utils from '../../components/utils';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../components/links';

export async function changePasswordFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.changePassword,
    dispatch,
    `/user/password/change/`,
    'post',
    '',
    form,
    true
  )();
}

const CreateOrderPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState<any>('');
  const [newPassword, setNewPassword] = useState<any>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<any>('');
  const [showPassword, setShowPassword] = useState<any>(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [error, setError] = useState('');

  const changePasswordStore = useSelector(
    (state: any) => state.changePasswordStore
  );

  useEffect(() => {
    // console.log('changePasswordStore', changePasswordStore);
    if (changePasswordStore) {
      if (changePasswordStore.data) {
        navigate(Links.home.url);
      } else if (changePasswordStore.error) {
        setError(changePasswordStore.error);
      }
    }
  }, [changePasswordStore]);

  useEffect(() => {
    setError('');
  }, [oldPassword, newPassword, newPasswordRepeat]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Submitted Data:', formData);
    // Добавьте логику отправки данных на сервер

    let notEmptyBool = true;

    if (oldPassword === '') {
      notEmptyBool = false;
    }

    if (newPassword === '') {
      notEmptyBool = false;
    }

    if (newPasswordRepeat === '') {
      notEmptyBool = false;
    }

    if (newPassword === '') {
      notEmptyBool = false;
    }

    if (newPassword.length < 8) {
      notEmptyBool = false;
    }

    if (newPasswordRepeat !== newPassword) {
      notEmptyBool = false;
    }

    setSubmitButtonClicked(true);

    if (notEmptyBool) {
      changePasswordFunc(dispatch, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordRepeat: newPasswordRepeat,
      });
    }
  };

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />
        <div className="max-w-xl mx-auto pt-[120px] pb-[60px] px-4 bg-white shadow-lg rounded-lg">
          <h1 className="text-xl font-semibold mb-6">Изменить пароль</h1>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="oldPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Старый пароль
              </label>

              <div className="relative mt-2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="oldPassword"
                  id="oldPassword"
                  value={oldPassword}
                  onChange={(e) => {
                    const value = e.target.value;

                    setOldPassword(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                <button
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                >
                  <Icon
                    icon={`mdi:eye${showPassword ? '-off' : ''}`}
                    className="w-[20px] h-[20px]"
                  />
                </button>
              </div>

              {submitButtonClicked && oldPassword === '' && (
                <div className="mt-[5px] text-sm text-red-500">
                  Поле является обязательным для заполнения
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Новый пароль
              </label>

              <div className="relative mt-2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => {
                    const value = e.target.value;

                    setNewPassword(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                <button
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                >
                  <Icon
                    icon={`mdi:eye${showPassword ? '-off' : ''}`}
                    className="w-[20px] h-[20px]"
                  />
                </button>
              </div>

              {submitButtonClicked && newPassword === '' && (
                <div className="mt-[5px] text-sm text-red-500">
                  Поле является обязательным для заполнения
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="newPasswordRepeat"
                className="block text-sm font-medium text-gray-700"
              >
                Повторить новый пароль
              </label>

              <div className="relative mt-2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="newPasswordRepeat"
                  id="newPasswordRepeat"
                  value={newPasswordRepeat}
                  onChange={(e) => {
                    const value = e.target.value;

                    setNewPasswordRepeat(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                <button
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                >
                  <Icon
                    icon={`mdi:eye${showPassword ? '-off' : ''}`}
                    className="w-[20px] h-[20px]"
                  />
                </button>
              </div>

              {submitButtonClicked && newPasswordRepeat === '' && (
                <div className="mt-[5px] text-sm text-red-500">
                  Поле является обязательным для заполнения
                </div>
              )}
            </div>

            {submitButtonClicked &&
            newPassword !== '' &&
            newPasswordRepeat !== '' &&
            newPasswordRepeat !== newPassword ? (
              <div className="mt-[5px] text-sm text-red-500">
                "Новый пароль" и "Повторить новый пароль" не одинаковы
              </div>
            ) : (
              submitButtonClicked &&
              newPassword !== '' &&
              newPasswordRepeat !== '' &&
              newPassword.length < 8 && (
                <div className="mt-[5px] text-sm text-red-500">
                  Длина нового пароля меньше 8 символов
                </div>
              )
            )}

            {error && (
              <div className="mt-[5px] text-sm text-red-500">{error}</div>
            )}

            <button
              className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              onClick={handleSubmit}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
};

export default CreateOrderPage;
