import React from 'react';
// import react router dom
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
// import pages

import Login from '../pages/auth/Login';
// import Registration from '../pages/auth/Registration';
import Error404 from '../pages/auth/Error404';
import Home from '../pages/home/Home';
import CreateOrder from '../pages/order/CreateOrder';
import EditOrder from '../pages/order/EditOrder';
import ChangePassword from '../pages/profile/ChangePassword';
import OrderTracking from '../pages/order/OrderTracking';
import SalaryDashboard from '../pages/superadmin/SalaryDashboard';
import AllOrderTable from '../pages/superadmin/AllOrderTable';
import SalaryPayment from '../pages/profile/SalaryPayment';
import AnalyticsTable from '../pages/superadmin/AnalyticsTable';
import Test from '../pages/home/Test';
import { Links } from './links';

const App = () => {
  // const [language, setLanguage] = React.useState("en"); // по умолчанию английский

  // React.useEffect(() => {
  //   const userLanguage = navigator.language || navigator.languages[0];
  //   // console.log("userLanguage", userLanguage);
  //   setLanguage(userLanguage);
  // }, []);

  return (
    <div className="">
      <Router>
        {/* <Header /> */}
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path={Links.login.url} element={<Login />} />
          {/* <Route path={Links.registration.url} element={<Registration />} /> */}
          <Route path={Links.home.url} element={<Home />} />
          <Route path={Links.order_create.url} element={<CreateOrder />} />
          <Route path="/order/edit/:orderId" element={<EditOrder />} />
          <Route
            path={Links.password_change.url}
            element={<ChangePassword />}
          />
          <Route path={Links.order_tracking.url} element={<OrderTracking />} />
          <Route path={Links.salary_table.url} element={<SalaryDashboard />} />
          <Route path={Links.all_order_table.url} element={<AllOrderTable />} />
          <Route path={Links.salary_payment.url} element={<SalaryPayment />} />
          <Route path={Links.analytics.url} element={<AnalyticsTable />} />
          {/* <Route path={'/test'} element={<Test />} /> */}
          {/* <Route path="/products/:productSlug" element={<ProductsDetails />} /> */}
          <Route path="*" element={<Error404 />} /> {/* маршрут для 404 */}
        </Routes>
        {/*<Sidebar />*/}
        {/* <Footer /> */}
      </Router>
    </div>
  );
};

export default App;
