import axios from 'axios';
import * as utils from '../../utils';
import * as constants from '../../constants';

const getFileName = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0'); // Форматируем день
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Форматируем месяц (месяцы с 0)
  const year = now.getFullYear(); // Год
  const hours = String(now.getHours()).padStart(2, '0'); // Часы
  const minutes = String(now.getMinutes()).padStart(2, '0'); // Минуты

  return `data_${day}-${month}-${year}_${hours}-${minutes}.xlsx`; // Форматируем имя файла
};

// Функция для получения конфигурации с токеном из cookies
const getAxiosConfig = () => {
  const tokenCookie = utils.GetCookieValue('token');
  let config: any = {};

  if (tokenCookie) {
    const tokenAccess = JSON.parse(tokenCookie)?.access;
    if (tokenAccess) {
      config['Authorization'] = `Bearer ${tokenAccess}`;
    }
  }

  return config;
};

// Функция для обновления токена через refresh
const refreshToken = async () => {
  try {
    const refreshToken = JSON.parse(
      utils.GetCookieValue('token') ?? '{}'
    ).refresh;

    if (!refreshToken) {
      throw new Error('Refresh token not available');
    }

    // Запрос на обновление токена
    const response = await axios.post(`${constants.API}/token/refresh/`, {
      refresh: refreshToken,
    });
    const newToken = response.data.access; // Предположим, что ответ содержит новый токен в поле 'access'

    // Сохраняем новый токен в cookies
    utils.SetCookieValue(
      'token',
      JSON.stringify(response.data)
      // 600
    );

    return newToken;
  } catch (error) {
    console.error('Ошибка при обновлении токена', error);
    throw error; // Если обновить токен не получилось, выбрасываем ошибку
  }
};

// Основная функция для скачивания Excel
const handleDownload = async () => {
  let config = getAxiosConfig();

  try {
    const response = await axios.get(`${constants.API}/user/excel/download/`, {
      responseType: 'blob',
      ...config,
    });

    // Получаем имя файла с текущей датой и временем
    const fileName = getFileName();

    // Создаём ссылку для скачивания файла
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Имя файла для скачивания

    document.body.appendChild(link); // Добавляем ссылку в DOM
    link.click(); // Имитируем клик по ссылке для скачивания
    document.body.removeChild(link); // Удаляем ссылку после скачивания
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Если токен истёк, обновляем токен и повторно отправляем запрос
      console.log('token refreshed');

      const newToken = await refreshToken(); // Обновляем токен
      config['Authorization'] = `Bearer ${newToken}`; // Обновляем конфигурацию с новым токеном

      // Повторно отправляем запрос с новым токеном
      const response = await axios.get(
        `${constants.API}/user/excel/download/`,
        {
          responseType: 'blob',
          ...config,
        }
      );

      // Получаем имя файла с текущей датой и временем
      const fileName = getFileName();

      // Создаём ссылку для скачивания файла
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Ошибка при скачивании файла', error);
    }
  }
};

export default handleDownload;
