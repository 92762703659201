export const Links = {
  home: {
    url: '/',
  },
  catalog: {
    url: '/catalog',
  },
  login: {
    url: '/auth',
  },
  order_create: {
    url: '/order/create',
  },
  // order_edit: {
  //   url: '/order/edit',
  // },
  password_change: {
    url: '/profile/password',
  },
  order_tracking: {
    url: '/tracking',
  },
  salary_table: {
    url: '/salary',
  },
  all_order_table: {
    url: '/superadmin/order',
  },
  salary_payment: {
    url: '/salary/payment',
  },
  analytics: {
    url: '/analytics',
  },
};
