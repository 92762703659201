import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import * as reducers from '../components/reducers';
import * as constants from '../components/constants';
import * as utils from '../components/utils';

export const reducer = combineReducers({
  token: reducers.constructorReducer(constants.token),

  logoutStore: reducers.constructorReducer(constants.logout),
  usersOrderStore: reducers.constructorReducer(constants.usersOrder),
  profileInfoStore: reducers.constructorReducer(constants.profileInfo),
  sendLoginDataStore: reducers.constructorReducer(constants.sendLoginData),
  salaryDataStore: reducers.constructorReducer(constants.salaryData),
  salaryPayDataStore: reducers.constructorReducer(constants.salaryPayData),
  createOrderDataStore: reducers.constructorReducer(constants.createOrderData),
  sendCreateOrderDataStore: reducers.constructorReducer(
    constants.sendCreateOrderData
  ),
  editOrderDataStore: reducers.constructorReducer(constants.editOrderData),
  sendEditOrderDataStore: reducers.constructorReducer(
    constants.sendEditOrderData
  ),
  trackingOrderDataStore: reducers.constructorReducer(
    constants.trackingOrderData
  ),
  allOrderTableDataStore: reducers.constructorReducer(
    constants.allOrderTableData
  ),
  changePasswordStore: reducers.constructorReducer(constants.changePassword),
  salaryPaymentDataStore: reducers.constructorReducer(
    constants.salaryPaymentData
  ),
  analyticsDataStore: reducers.constructorReducer(constants.analyticsData),
  sendAnalyticsDataStore: reducers.constructorReducer(
    constants.sendAnalyticsData
  ),
  deleteImageStore: reducers.constructorReducer(constants.deleteImage),
  addImageStore: reducers.constructorReducer(constants.addImage),
});

const tokenFromCookie = JSON.parse(utils.GetCookieValue('token') ?? '{}');

const cartStorage = localStorage.getItem('cart')
  ? // @ts-ignore
    JSON.parse(localStorage.getItem('cart'))
  : [];

if (cartStorage.length === 0) {
  localStorage.setItem('cart', JSON.stringify([]));
}

const preloadedState = {
  token: tokenFromCookie
    ? { load: false, data: tokenFromCookie }
    : { load: false },
};

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware().concat(thunk).concat(localStorageMiddleware), // Добавляем middleware для LocalStorage,
    getDefaultMiddleware().concat(thunk),
  preloadedState: preloadedState,
});
