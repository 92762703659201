import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/ui/Header/HeaderHome';
import { Icon } from '@iconify/react';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/ui/LoadingScreen';
import LoadingModalScreen from '../../components/ui/LoadingModalScreen';
import ImageTableEdit from '../../components/ui/OrderTracking/ImageTableEdit';
import ImageTableEditView from '../../components/ui/OrderTracking/ImageTableEditView';
import { Links } from '../../components/links';
import HelpModal from '../../components/ui/HelpModal';

export async function editOrderDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.editOrderData,
    dispatch,
    `/user/edit/order/data/`,
    'post',
    '',
    form,
    true
  )();
}

export async function sendEditOrderDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.sendEditOrderData,
    dispatch,
    `/user/edit/order/`,
    'post',
    '',
    form,
    true
  )();
}

const EditOrderPage: React.FC = () => {
  // Функция для получения текущей даты в формате YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [role, setRole] = useState<any>('');
  const [isEditableOrderTrackingAdmin, setIsEditableOrderTrackingAdmin] =
    useState<any>(false);
  const [isEditableSuperAdmin, setIsEditableSuperAdmin] = useState<any>(false);
  const [error, setError] = useState<any>('');
  const [errorResponse, setErrorResponse] = useState<any>('');

  const [hidePositions, setHidePositions] = useState<any>(false);

  const [user, setUser] = useState<any>('');
  const [shop, setShop] = useState<any>('');
  const [ownedByUser, setOwnedByUser] = useState<any>(false);
  const [orderNumeration, setOrderNumeration] = useState<any>('');
  const [date, setDate] = useState<any>(getCurrentDate());
  const [bandOrArtist, setBandOrArtist] = useState<any>('');
  const [surcharge, setSurcharge] = useState<any>('');
  const [totalAmount, setTotalAmount] = useState<any>('');
  const [adminSalary, setAdminSalary] = useState<any>('');
  const [trackingCode, setTrackingCode] = useState<any>('');
  const [orderStatus, setOrderStatus] = useState<any>('Выкуплен');
  const [orderCountry, setOrderCountry] = useState<any>('Корея');
  const [sellerNick, setSellerNick] = useState<any>('@');
  const [overpayment, setOverpayment] = useState<any>('');
  const [paymentPostLinkKor, setPaymentPostLinkKor] = useState<any>('');
  const [paymentPostLink, setPaymentPostLink] = useState<any>('');
  const [comment, setComment] = useState<any>('');
  const [salaryPaymentStatus, setSalaryPaymentStatus] = useState<any>('');
  const [listOfImagePath, setListOfImagePath] = useState<any>([]);
  const [processTextVal, setProcessTextVal] = useState<any>('');
  const [processTextValType, setProcessTextValType] = useState<any>('position');

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [adminPercentageProfit, setAdminPercentageProfit] = useState(null);
  const [convertFromUEtoTengeShop, setConvertFromUEtoTengeShop] =
    useState(null); // 5000
  const [convertFromUEtoTenge, setConvertFromUEtoTenge] = useState(null); // 2.56
  const [listOfCountry, setListOfCountry] = useState([]);
  const [listOfUsers, setListOfUsers] = useState([]);
  const [listOfShop, setListOfShop] = useState([]);
  const [orderItems, setOrderItems] = useState([
    {
      title: '',
      itemPriceInConventionalUnits: '',
      buyerNick: '@',
      itemPriceInTenge: 0,
      itemPriceInTengeExcluding: 0,
      profit: 0,
      paymentStatus: 'Не оплачено',
    },
  ]);

  const [showStickyDiv, setShowStickyDiv] = useState(false);
  const dateInputRef = useRef<HTMLInputElement>(null); // Создаем ref для input с датой

  const listOfOrderStatus = [
    'Выкуплен',
    'Отправлен к кор',
    'Прибыл к кор',
    'Прибыл в Кз',
    'Отменен',
  ];

  const editOrderDataStore = useSelector(
    (state: any) => state.editOrderDataStore
  );

  const sendEditOrderDataStore = useSelector(
    (state: any) => state.sendEditOrderDataStore
  );

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    const handleScroll = () => {
      if (dateInputRef.current) {
        const rect = dateInputRef.current.getBoundingClientRect();
        setShowStickyDiv(rect.top <= 0); // Показываем div, если input пересекает верх
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    for (let i = 0; i < listOfUsers.length; i++) {
      //@ts-ignore
      if (listOfUsers[i].username === user) {
        //@ts-ignore
        setAdminPercentageProfit(listOfUsers[i].percentage_profit);
      }
    }
  }, [user]);

  useEffect(() => {
    editOrderDataFunc(dispatch, { orderId: params.orderId });
  }, [params]);

  useEffect(() => {
    // console.log('profileInfoStore', profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      setRole(profileInfoStore.data.response.role);
      // Определяем, может ли input быть активным
      setIsEditableOrderTrackingAdmin(
        profileInfoStore.data.response.role === 'superadmin' ||
          profileInfoStore.data.response.role === 'order_tracking_admin'
      );
      setIsEditableSuperAdmin(
        profileInfoStore.data.response.role === 'superadmin'
      );
    }
  }, [profileInfoStore]);

  useEffect(() => {
    // console.log('sendEditOrderDataStore', sendEditOrderDataStore);
    if (sendEditOrderDataStore) {
      if (sendEditOrderDataStore.data) {
        if (sendEditOrderDataStore.data.response === 'OK') {
          navigate(Links.home.url);
        }
      } else if (sendEditOrderDataStore.error) {
        // setErrorResponse(sendEditOrderDataStore.error);
        alert(sendEditOrderDataStore.error);
      }
    }
  }, [sendEditOrderDataStore]);

  useEffect(() => {
    // console.log('editOrderDataStore', editOrderDataStore);
    if (editOrderDataStore) {
      if (editOrderDataStore.data) {
        const response = editOrderDataStore.data.response;

        setUser(response.creater);
        setShop(response.shop);
        setListOfShop(response.list_of_shop);
        setListOfUsers(response.list_of_users);
        setListOfImagePath(response.list_of_image_path);
        setOwnedByUser(response.owned_by_user);
        setOrderNumeration(response.order_numeration);
        setDate(response.order_date);
        setBandOrArtist(response.band_or_artist);
        setSurcharge(response.surcharge);
        setTrackingCode(response.tracking_code);
        setOrderStatus(response.order_status);
        setSellerNick(response.seller_nick);
        setOverpayment(response.overpayment);
        setPaymentPostLinkKor(response.payment_post_link_kor);
        setPaymentPostLink(response.payment_post_link);
        setComment(response.comment);
        // setAdminPercentageProfit(response.admin_percentage_profit);
        setConvertFromUEtoTenge(response.convert_from_ue_to_tenge);
        setConvertFromUEtoTengeShop(response.convert_from_ue_to_tenge_shop);
        setOrderCountry(response.order_country);
        setListOfCountry(response.list_of_order_country);
        setSalaryPaymentStatus(response.salary_payment_status);
        setOrderItems(response.order_items);
      } else if (editOrderDataStore.error) {
        setError(editOrderDataStore.error);
      }
    }
  }, [editOrderDataStore]);

  useEffect(() => {
    let adminSalaryTmp = 0;
    let totalAmountTmp = 0;

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].profit) {
        //@ts-ignore
        adminSalaryTmp += parseFloat(orderItems[i].profit);
      }
      if (orderItems[i].itemPriceInTengeExcluding) {
        //@ts-ignore
        totalAmountTmp += parseFloat(orderItems[i].itemPriceInTengeExcluding);
      }
    }

    if (surcharge) {
      totalAmountTmp += parseFloat(surcharge);
    }

    if (adminPercentageProfit) {
      adminSalaryTmp *= adminPercentageProfit / 100;
    }

    adminSalaryTmp = parseFloat(adminSalaryTmp.toFixed(2));
    totalAmountTmp = parseFloat(totalAmountTmp.toFixed(2));

    setAdminSalary(adminSalaryTmp);
    setTotalAmount(totalAmountTmp);

    // setOrderItems(orderItemsTmp);
  }, [orderItems, surcharge, adminPercentageProfit]);

  const handleChangeOrderItem = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    let orderItemsTmp = structuredClone(orderItems);

    if (name === 'itemPriceInConventionalUnits') {
      // Разрешить пустое значение для очистки
      if (value === '') {
        //@ts-ignore
        orderItemsTmp[index][name] = '';
        //@ts-ignore
        orderItemsTmp[index].itemPriceInTenge = 0;
        orderItemsTmp[index].itemPriceInTengeExcluding = 0;
        orderItemsTmp[index].profit = 0;
      } else {
        const valueTmp = parseFloat(value);
        if (valueTmp === 0) {
          //@ts-ignore
          orderItemsTmp[index][name] = 0;
          //@ts-ignore
          orderItemsTmp[index].itemPriceInTenge = 0;
          orderItemsTmp[index].itemPriceInTengeExcluding = 0;
          orderItemsTmp[index].profit = 0;
        } else if (!isNaN(valueTmp) && valueTmp >= 0) {
          //@ts-ignore
          orderItemsTmp[index][name] = valueTmp;

          //@ts-ignore
          orderItemsTmp[index].itemPriceInTengeExcluding =
            //@ts-ignore
            parseFloat((valueTmp * convertFromUEtoTengeShop).toFixed(2));

          //@ts-ignore
          orderItemsTmp[index].itemPriceInTenge =
            //@ts-ignore
            parseFloat(((valueTmp * 10000) / convertFromUEtoTenge).toFixed(2));

          //@ts-ignore
          orderItemsTmp[index].profit = parseFloat(
            //@ts-ignore
            (
              orderItemsTmp[index].itemPriceInTengeExcluding -
              //@ts-ignore
              orderItemsTmp[index].itemPriceInTenge
            ).toFixed(2)
          );
        }
      }
    } else if (name === 'title') {
      //@ts-ignore
      orderItemsTmp[index][name] = value;
    } else if (name === 'paymentStatus') {
      //@ts-ignore
      orderItemsTmp[index][name] = value;
    } else if (name === 'buyerNick') {
      if (!value.startsWith('@')) {
        orderItemsTmp[index][name] = '@' + value.replace(/@/g, '').trim();
      } else {
        orderItemsTmp[index][name] = value
          .replace(/@/g, '')
          .trim()
          .replace(/^/, '@');
      }
    }
    setOrderItems(orderItemsTmp);
  };

  const handleAddOrderItem = () => {
    let orderItemsTmp = structuredClone(orderItems);

    if (
      orderItemsTmp[orderItemsTmp.length - 1].title.trim() &&
      orderItemsTmp[orderItemsTmp.length - 1].itemPriceInConventionalUnits
        .toString()
        .trim() &&
      parseFloat(
        orderItemsTmp[orderItemsTmp.length - 1].itemPriceInConventionalUnits
      ) !== 0
    ) {
      orderItemsTmp.push({
        title: '',
        itemPriceInConventionalUnits: '',
        buyerNick: '@',
        itemPriceInTenge: 0,
        itemPriceInTengeExcluding: 0,
        profit: 0,
        paymentStatus: 'Не оплачено',
      });

      setOrderItems(orderItemsTmp);
    }
  };

  const handleRemoveOrderItem = (indexToRemove: number) => {
    let orderItemsTmp = structuredClone(orderItems);

    if (orderItemsTmp.length > 1) {
      orderItemsTmp.splice(indexToRemove, 1);

      setOrderItems(orderItemsTmp);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Submitted Data:', formData);
    // Добавьте логику отправки данных на сервер

    let notEmptyBool = true;

    if (role === 'superadmin') {
      if (user === '') {
        notEmptyBool = false;
      }

      if (shop === '') {
        notEmptyBool = false;
      }

      if (orderNumeration === '' || orderNumeration === 0) {
        notEmptyBool = false;
      }

      if (date === '' || date === 0) {
        notEmptyBool = false;
      }

      if (bandOrArtist === '' || bandOrArtist === 0) {
        notEmptyBool = false;
      }

      if (orderCountry === '' || orderCountry === 0) {
        notEmptyBool = false;
      }

      if (
        paymentPostLinkKor !== '' &&
        !paymentPostLinkKor.startsWith('https://t.me/c/')
      ) {
        notEmptyBool = false;
      }

      if (
        shop === 'Meloddy Shop' &&
        !paymentPostLink.startsWith('https://t.me/meloddyshop/')
      ) {
        notEmptyBool = false;
      }

      if (
        shop === 'Clouddy Shop' &&
        !paymentPostLink.startsWith('https://t.me/clouddyshop/')
      ) {
        notEmptyBool = false;
      }

      for (let i = 0; i < orderItems.length; i++) {
        if (
          orderItems[i].title === '' ||
          parseFloat(orderItems[i].title) === 0
        ) {
          notEmptyBool = false;
        }

        // if (
        //   orderItems[i].buyerNick === '@' ||
        //   parseFloat(orderItems[i].buyerNick) === 0
        // ) {
        //   notEmptyBool = false;
        // }
      }
    } else if (role === 'order_tracking_admin') {
      if (user === '') {
        notEmptyBool = false;
      }

      if (shop === '') {
        notEmptyBool = false;
      }

      if (orderNumeration === '' || orderNumeration === 0) {
        notEmptyBool = false;
      }

      if (date === '' || date === 0) {
        notEmptyBool = false;
      }

      if (bandOrArtist === '' || bandOrArtist === 0) {
        notEmptyBool = false;
      }

      if (orderCountry === '' || orderCountry === 0) {
        notEmptyBool = false;
      }

      if (ownedByUser) {
        if (
          paymentPostLinkKor !== '' &&
          !paymentPostLinkKor.startsWith('https://t.me/c/')
        ) {
          notEmptyBool = false;
        }
        // if (surcharge === '' || surcharge === 0) {
        //   notEmptyBool = false;
        // }
        // if (sellerNick === '@' || sellerNick === 0) {
        //   notEmptyBool = false;
        // }
      }

      if (
        shop === 'Meloddy Shop' &&
        !paymentPostLink.startsWith('https://t.me/meloddyshop/')
      ) {
        notEmptyBool = false;
      }

      if (
        shop === 'Clouddy Shop' &&
        !paymentPostLink.startsWith('https://t.me/clouddyshop/')
      ) {
        notEmptyBool = false;
      }

      for (let i = 0; i < orderItems.length; i++) {
        if (
          orderItems[i].title === '' ||
          parseFloat(orderItems[i].title) === 0
        ) {
          notEmptyBool = false;
        }

        // if (
        //   orderItems[i].buyerNick === '@' ||
        //   parseFloat(orderItems[i].buyerNick) === 0
        // ) {
        //   notEmptyBool = false;
        // }

        if (ownedByUser) {
          //   if (
          //     orderItems[i].itemPriceInConventionalUnits === '' ||
          //     parseFloat(orderItems[i].itemPriceInConventionalUnits) === 0
          //   ) {
          //     notEmptyBool = false;
          //   }
        }
      }
    } else if (role === 'admin') {
      //   if (surcharge === '' || surcharge === 0) {
      //     notEmptyBool = false;
      //   }

      if (sellerNick === '@' || sellerNick === 0) {
        notEmptyBool = false;
      }

      if (
        (paymentPostLinkKor !== '' &&
          !paymentPostLinkKor.startsWith('https://t.me/c/')) ||
        paymentPostLinkKor === ''
      ) {
        notEmptyBool = false;
      }

      for (let i = 0; i < orderItems.length; i++) {
        if (
          orderItems[i].itemPriceInConventionalUnits === '' ||
          parseFloat(orderItems[i].itemPriceInConventionalUnits) === 0
        ) {
          notEmptyBool = false;
        }
      }
    }

    // console.log('notEmptyBool', notEmptyBool);

    setSubmitButtonClicked(true);

    if (notEmptyBool) {
      sendEditOrderDataFunc(dispatch, {
        username: user,
        shop: shop,
        orderId: params.orderId,
        orderNumeration: orderNumeration,
        date: date,
        bandOrArtist: bandOrArtist,
        surcharge: surcharge || 0,
        totalAmount: totalAmount || 0,
        adminSalary: adminSalary || 0,
        trackingCode: trackingCode,
        orderCountry: orderCountry,
        orderStatus: orderStatus,
        sellerNick: sellerNick,
        overpayment: overpayment || 0,
        paymentPostLinkKor: paymentPostLinkKor,
        paymentPostLink: paymentPostLink,
        comment: comment,
        salaryPaymentStatus: salaryPaymentStatus,
        // adminPercentageProfit: adminPercentageProfit,
        // convertFromUEtoTenge: convertFromUEtoTenge,
        // convertFromUEtoTengeShop: convertFromUEtoTengeShop,
        orderItems: orderItems,
      });
    }
  };

  const processText = (text: string) => {
    // Разбиваем текст на строки
    const lines = text.split('\n');

    // Список результатов
    const resultList: { items: string; contact: string }[] = [];

    lines.forEach((line) => {
      // Убираем нумерацию
      const withoutNumber = line.replace(/^\d+\.\s*/, ''); // Убираем "1. ", "2. " и т.д.

      // Проверяем формат строки
      if (withoutNumber.includes(' - ')) {
        const parts = withoutNumber.split(' - ');
        const [items, rest] = parts;

        // Извлекаем контакт (после "@") и удаляем возможные эмодзи
        const match = rest.match(/@[\w\d_]+/); // Поиск слова с "@" (без эмодзи)
        const contact = match ? match[0] : '@';

        resultList.push({
          items: items.trim(),
          contact: contact.trim(),
        });
      }
    });

    let orderItemsTmp = [];

    for (let i = 0; i < resultList.length; i++) {
      orderItemsTmp.push({
        title: resultList[i].items,
        itemPriceInConventionalUnits: '',
        buyerNick: resultList[i].contact,
        itemPriceInTenge: 0,
        itemPriceInTengeExcluding: 0,
        profit: 0,
        paymentStatus: 'Не оплачено',
      });
    }

    setOrderItems(orderItemsTmp);
    // console.log('orderItemsTmp', orderItemsTmp);
  };

  const processLines = (text: string) => {
    // Разделяем текст на блоки по "->"
    const blocks = text
      .split('->')
      .map((block) => block.trim())
      .filter(Boolean);

    const result: {
      title: string;
      value: string;
      description: string;
      contact: string;
    }[] = [];

    blocks.forEach((block) => {
      const [rawTitle, ...lines] = block.split('\n').map((line) => line.trim());
      const titleMatch = rawTitle.match(/(.+)-\s*([\d.]+)/);
      const title = titleMatch ? titleMatch[1].trim() : rawTitle.trim();
      const value = titleMatch ? titleMatch[2].trim() : '';

      const contacts = lines
        .filter((line) => line.includes('@'))
        .map((line) => {
          const contactMatch = line.match(/(@\w+)/); // Ищем контакт с "@"
          const contact = contactMatch ? contactMatch[0] : '@';

          const descriptionTmp = line.replace(contact, '').trim(); // Всё, что после контакта
          const descriptionList = descriptionTmp.split('  ');
          let description = '';
          if (descriptionList.length > 1) {
            description = descriptionList[descriptionList.length - 1];
          }
          return { contact, description };
        });

      if (contacts.length > 0) {
        contacts.forEach(({ contact, description }) => {
          result.push({
            title: `${title} ${description}`,
            value,
            description,
            contact,
          });
        });
      } else {
        result.push({ title, value: '', description: '', contact: '@' });
      }
    });

    let orderItemsTmp = [];

    for (let i = 0; i < result.length; i++) {
      if (result[i].value === '') {
        orderItemsTmp.push({
          title: `Альбом (${result[i].title}${result[i].description && ` ${result[i].description}`})`,
          itemPriceInConventionalUnits: '',
          buyerNick: result[i].contact,
          itemPriceInTenge: 0,
          itemPriceInTengeExcluding: 0,
          profit: 0,
          paymentStatus: 'Не оплачено',
        });
      } else {
        let itemPriceInTengeExcludingTmp = parseFloat(
          //@ts-ignore
          (parseFloat(result[i].value) * convertFromUEtoTengeShop).toFixed(2)
        );

        let itemPriceInTengeTmp = parseFloat(
          (
            (parseFloat(result[i].value) * 10000) /
            //@ts-ignore
            convertFromUEtoTenge
          ).toFixed(2)
        );

        let profitTmp = parseFloat(
          (itemPriceInTengeExcludingTmp - itemPriceInTengeTmp).toFixed(2)
        );

        orderItemsTmp.push({
          title: `Альбом (${result[i].title}${result[i].description && `- ${result[i].description}`})`,
          itemPriceInConventionalUnits: parseFloat(result[i].value),
          buyerNick: result[i].contact,
          itemPriceInTenge: itemPriceInTengeTmp,
          itemPriceInTengeExcluding: itemPriceInTengeExcludingTmp,
          profit: profitTmp,
          paymentStatus: 'Не оплачено',
        });
      }
    }
    //@ts-ignore
    setOrderItems(orderItemsTmp);
  };

  //   if (createOrderDataStore && createOrderDataStore.load) {
  //     return (
  //       <div>
  //         <LoadingScreen isLoading={createOrderDataStore.load} />
  //       </div>
  //     );
  //   }

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(editOrderDataStore && editOrderDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Вернуться на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-xl mx-auto pt-[120px] pb-[60px] px-4 bg-white shadow-lg rounded-lg">
            {showStickyDiv && (
              <div className="fixed top-[97px] left-0 w-full bg-[#9086A2] text-white text-center p-2 shadow-md z-10">
                Разбор #{orderNumeration}
              </div>
            )}

            <LoadingModalScreen isLoad={sendEditOrderDataStore.load} />

            <div className="mb-6 text-xl font-semibold">
              Редактировать разбор
            </div>

            {role === 'superadmin' ? (
              <div className="space-y-4">
                {/* Магазин */}
                <div>
                  <label
                    htmlFor="shop"
                    className="block text-sm font-medium text-green-500"
                  >
                    Магазин*
                  </label>
                  <select
                    id="shop"
                    name="shop"
                    value={shop}
                    onChange={(e) => {
                      setShop(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={''}>--Выберите магазин--</option>
                    {listOfShop.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && shop === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Администратор */}
                <div>
                  <label
                    htmlFor="user"
                    className="block text-sm font-medium text-green-500"
                  >
                    Администратор*
                  </label>
                  <select
                    id="user"
                    name="user"
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {listOfUsers.map((item: any, index: number) => (
                      <option key={index} value={item.username}>
                        {item.username} ({item.shop_name})
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && user === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Номер разбора */}
                <div>
                  <label
                    htmlFor="orderNumeration"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Номер разбора*
                  </label>
                  <input
                    type="text"
                    ref={dateInputRef} // Привязываем ref к input
                    name="orderNumeration"
                    id="orderNumeration"
                    value={orderNumeration}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setOrderNumeration(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && orderNumeration === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Дата разбора */}
                <div>
                  <label
                    htmlFor="date"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Дата разбора*
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={date || getCurrentDate}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setDate(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && date === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Название группы */}
                <div>
                  <label
                    htmlFor="bandOrArtist"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Название группы*
                  </label>
                  <input
                    type="text"
                    name="bandOrArtist"
                    id="bandOrArtist"
                    value={bandOrArtist}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setBandOrArtist(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && bandOrArtist === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Страна разбора */}
                <div>
                  <label
                    htmlFor="orderCountry"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Страна разбора*
                  </label>
                  <select
                    id="orderCountry"
                    name="orderCountry"
                    value={orderCountry}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      setOrderCountry(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {listOfCountry.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && orderCountry === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="listOfImagePath"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Изображения
                  </label>

                  <ImageTableEdit
                    images={listOfImagePath}
                    orderId={params.orderId}
                  />
                </div>

                {/* Тип разбора */}
                <div>
                  <label
                    htmlFor="orderCountry"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Тип разбора*
                  </label>

                  <select
                    id="processTextValType"
                    name="processTextValType"
                    value={processTextValType}
                    onChange={(e) => {
                      setProcessTextValType(e.target.value);
                    }}
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={'position'}>Позиции</option>

                    <option value={'album'}>Альбомы</option>
                  </select>
                </div>

                {/* Текст для перевода в позиции */}
                <div>
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="processTextVal"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Текст для перевода в позиции
                    </label>

                    <HelpModal />
                  </div>
                  <textarea
                    name="processTextVal"
                    id="processTextVal"
                    value={processTextVal}
                    onChange={(e) => {
                      setProcessTextVal(e.target.value);
                    }}
                    className="mt-1 block w-full h-[200px] p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  <div className="mt-2 flex justify-between items-center">
                    <button
                      className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                      onClick={() => {
                        if (processTextValType === 'position') {
                          console.log('CLICKED');
                          processText(processTextVal);
                        } else if (processTextValType === 'album') {
                          processLines(processTextVal);
                        }
                      }}
                    >
                      Сгенерировать
                    </button>

                    <button
                      className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                      onClick={() => {
                        setProcessTextVal('');
                      }}
                    >
                      Очистить
                    </button>
                  </div>
                </div>

                <div className="">Количество позиций: {orderItems.length}</div>

                <div className="flex justify-between items-center">
                  <button
                    className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                    onClick={() => {
                      setHidePositions(!hidePositions);
                    }}
                  >
                    {hidePositions ? 'Показать позиции' : 'Скрыть позиции'}
                  </button>

                  {!hidePositions && (
                    <button
                      className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                      onClick={() => {
                        setOrderItems([
                          {
                            title: '',
                            itemPriceInConventionalUnits: '',
                            buyerNick: '@',
                            itemPriceInTenge: 0,
                            itemPriceInTengeExcluding: 0,
                            profit: 0,
                            paymentStatus: 'Не оплачено',
                          },
                        ]);
                      }}
                    >
                      Очистить все позиции
                    </button>
                  )}
                </div>

                {!hidePositions && (
                  <div>
                    <div className="space-y-4">
                      {orderItems.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="space-y-4 p-4 bg-gray-200 rounded-[10px]"
                        >
                          <div
                            className={`flex justify-between items-center text-red-500`}
                          >
                            <Icon
                              icon="streamline:delete-1-solid"
                              className="invisible"
                            />

                            <button
                              // disabled={!isEditableSuperAdmin}
                              onClick={() => {
                                handleRemoveOrderItem(index);
                              }}
                            >
                              <Icon icon="streamline:delete-1-solid" />
                            </button>
                          </div>

                          {/* Название карты */}
                          <div>
                            <label
                              htmlFor="title"
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Название позиции*
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              value={item.title}
                              // disabled={!isEditableSuperAdmin}
                              onChange={(e) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />

                            {submitButtonClicked && item.title === '' && (
                              <div className="mt-[5px] text-sm text-red-500">
                                Поле является обязательным для заполнения
                              </div>
                            )}
                          </div>

                          {/* Ник покупателя */}
                          <div>
                            <label
                              htmlFor="buyerNick"
                              // className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Ник покупателя*
                            </label>
                            <input
                              type="text"
                              name="buyerNick"
                              id="buyerNick"
                              value={item.buyerNick}
                              // disabled={!isEditableOrderTrackingAdmin}
                              onChange={(e) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />

                            {/* {submitButtonClicked && item.buyerNick === '@' && (
                              <div className="mt-[5px] text-sm text-red-500">
                                Поле является обязательным для заполнения
                              </div>
                            )} */}
                          </div>

                          {/* Цена каждой карты в у.е. */}
                          <div>
                            <label
                              htmlFor="itemPriceInConventionalUnits"
                              // className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Цена позиции в у.е.
                            </label>
                            <input
                              type="number"
                              name="itemPriceInConventionalUnits"
                              id="itemPriceInConventionalUnits"
                              value={item.itemPriceInConventionalUnits}
                              // disabled={!isEditableSuperAdmin}
                              onChange={(e) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          {/* Цена в тенге по курсу */}
                          <div>
                            <label
                              htmlFor="itemPriceInTenge"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Цена в тенге по курсу (автоматически)
                            </label>
                            <input
                              type="text"
                              name="itemPriceInTenge"
                              id="itemPriceInTenge"
                              value={item.itemPriceInTenge}
                              disabled
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          {/* Цена в тенге (без учетов) */}
                          <div>
                            <label
                              htmlFor="itemPriceInTengeExcluding"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Цена в тенге (без учетов) (автоматически)
                            </label>
                            <input
                              type="text"
                              name="itemPriceInTengeExcluding"
                              id="itemPriceInTengeExcluding"
                              value={item.itemPriceInTengeExcluding}
                              disabled
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          {/* Прибыль */}
                          <div>
                            <label
                              htmlFor="profit"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Прибыль (автоматически)
                            </label>
                            <input
                              type="text"
                              name="profit"
                              id="profit"
                              value={item.profit}
                              disabled
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>

                          {/* Статус оплаты */}
                          <div>
                            <label
                              htmlFor="paymentStatus"
                              // className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Статус оплаты
                            </label>
                            <select
                              id="paymentStatus"
                              name="paymentStatus"
                              value={item.paymentStatus}
                              // disabled={!isEditableOrderTrackingAdmin}
                              onChange={(e: any) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value={'Не оплачено'}>Не оплачено</option>
                              <option value={'Оплачено'}>Оплачено</option>
                            </select>
                          </div>

                          {submitButtonClicked && item.paymentStatus === '' && (
                            <div className="mt-[5px] text-sm text-red-500">
                              Поле является обязательным для заполнения
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-between items-center">
                      <button
                        //   disabled={!isEditableSuperAdmin}
                        //   className={`p-2 text-sm rounded text-white ${isEditableSuperAdmin ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-500'}`}
                        className={`p-2 text-sm rounded text-white bg-blue-500 hover:bg-blue-600`}
                        onClick={() => {
                          handleAddOrderItem();
                        }}
                      >
                        + Добавить карту в разбор
                      </button>
                    </div>
                  </div>
                )}

                {/* Учеты */}
                <div>
                  <label
                    htmlFor="surcharge"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Учеты
                  </label>
                  <input
                    type="text"
                    name="surcharge"
                    id="surcharge"
                    value={surcharge}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setSurcharge(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Итого по разбору */}
                <div>
                  <label
                    htmlFor="totalAmount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Итого по разбору (автоматически)
                  </label>
                  <input
                    type="text"
                    name="totalAmount"
                    id="totalAmount"
                    value={totalAmount}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Зарплата */}
                <div>
                  <label
                    htmlFor="adminSalary"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Зарплата (автоматически)
                  </label>
                  <input
                    type="text"
                    name="adminSalary"
                    id="adminSalary"
                    value={adminSalary}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Статус выплаты зарплаты */}
                <div>
                  <label
                    htmlFor="salaryPaymentStatus"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Статус выплаты зарплаты*
                  </label>
                  <select
                    id="salaryPaymentStatus"
                    name="salaryPaymentStatus"
                    value={salaryPaymentStatus}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e: any) => {
                      setSalaryPaymentStatus(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={'Не оплачено'}>Не оплачено</option>
                    <option value={'Оплачено'}>Оплачено</option>
                  </select>

                  {submitButtonClicked && salaryPaymentStatus === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Трек-код посылки */}
                <div>
                  <label
                    htmlFor="trackingCode"
                    className="block text-sm font-medium text-green-500"
                  >
                    Трек-код посылки
                  </label>
                  <input
                    type="text"
                    name="trackingCode"
                    id="trackingCode"
                    value={trackingCode}
                    onChange={(e) => {
                      const value = e.target.value;

                      setTrackingCode(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Статус разбора */}
                <div>
                  <label
                    htmlFor="orderStatus"
                    //   className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Статус разбора*
                  </label>
                  <select
                    id="orderStatus"
                    name="orderStatus"
                    value={orderStatus}
                    //   disabled={!isEditableOrderTrackingAdmin}
                    onChange={(e) => {
                      setOrderStatus(e.target.value);
                    }}
                    className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                  >
                    {listOfOrderStatus.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && orderStatus === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Ник продавца */}
                <div>
                  <label
                    htmlFor="sellerNick"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ник продавца*
                  </label>
                  <input
                    type="text"
                    name="sellerNick"
                    id="sellerNick"
                    value={sellerNick}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (!value.startsWith('@')) {
                        setSellerNick('@' + value.replace(/@/g, '').trim());
                      } else {
                        setSellerNick(
                          value.replace(/@/g, '').trim().replace(/^/, '@')
                        );
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Ссылка на пост с оплатой кор */}
                <div>
                  <label
                    htmlFor="paymentPostLinkKor"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ссылка на пост с оплатой кор*
                  </label>
                  <input
                    type="text"
                    name="paymentPostLinkKor"
                    id="paymentPostLinkKor"
                    value={paymentPostLinkKor}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setPaymentPostLinkKor(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked &&
                    paymentPostLinkKor !== '' &&
                    !paymentPostLinkKor.startsWith('https://t.me/c/') && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/c/"
                      </div>
                    )}
                </div>

                {/* Ссылка на пост с оплатой */}
                <div>
                  <label
                    htmlFor="paymentPostLink"
                    //   className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ссылка на пост с оплатой*
                  </label>
                  <input
                    type="text"
                    name="paymentPostLink"
                    id="paymentPostLink"
                    value={paymentPostLink}
                    //   disabled={!isEditableOrderTrackingAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setPaymentPostLink(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {shop === 'Meloddy Shop' &&
                    submitButtonClicked &&
                    paymentPostLink !== '' &&
                    !paymentPostLink.startsWith(
                      'https://t.me/meloddyshop/'
                    ) && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/meloddyshop/"
                      </div>
                    )}

                  {shop === 'Clouddy Shop' &&
                    submitButtonClicked &&
                    paymentPostLink !== '' &&
                    !paymentPostLink.startsWith(
                      'https://t.me/clouddyshop/'
                    ) && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/clouddyshop/"
                      </div>
                    )}
                </div>

                {/* Переплата по разбору */}
                <div>
                  <label
                    htmlFor="overpayment"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Переплата по разбору
                  </label>
                  <input
                    type="text"
                    name="overpayment"
                    id="overpayment"
                    value={overpayment}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setOverpayment(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Комментарий */}
                <div>
                  <label
                    htmlFor="comment"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Комментарий
                  </label>
                  <textarea
                    name="comment"
                    id="comment"
                    value={comment}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setComment(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <button
                  className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                  onClick={handleSubmit}
                >
                  Редактировать разбор
                </button>
              </div>
            ) : role === 'order_tracking_admin' ? (
              <div className="space-y-4">
                {/* Магазин */}
                <div>
                  <label
                    htmlFor="shop"
                    className="block text-sm font-medium text-green-500"
                  >
                    Магазин*
                  </label>
                  <select
                    id="shop"
                    name="shop"
                    value={shop}
                    onChange={(e) => {
                      setShop(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={''}>--Выберите магазин--</option>
                    {listOfShop.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && shop === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Администратор */}
                <div>
                  <label
                    htmlFor="user"
                    className="block text-sm font-medium text-green-500"
                  >
                    Администратор*
                  </label>
                  <select
                    id="user"
                    name="user"
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {listOfUsers.map((item: any, index: number) => (
                      <option key={index} value={item.username}>
                        {item.username} ({item.shop_name})
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && user === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Номер разбора */}
                <div>
                  <label
                    htmlFor="orderNumeration"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Номер разбора*
                  </label>
                  <input
                    type="text"
                    ref={dateInputRef} // Привязываем ref к input
                    name="orderNumeration"
                    id="orderNumeration"
                    value={orderNumeration}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setOrderNumeration(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && orderNumeration === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Дата разбора */}
                <div>
                  <label
                    htmlFor="date"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Дата разбора*
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={date}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setDate(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && date === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Название группы */}
                <div>
                  <label
                    htmlFor="bandOrArtist"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Название группы*
                  </label>
                  <input
                    type="text"
                    name="bandOrArtist"
                    id="bandOrArtist"
                    value={bandOrArtist}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setBandOrArtist(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && bandOrArtist === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Страна разбора */}
                <div>
                  <label
                    htmlFor="orderCountry"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Страна разбора*
                  </label>
                  <select
                    id="orderCountry"
                    name="orderCountry"
                    value={orderCountry}
                    //   disabled={!isEditableSuperAdmin}
                    onChange={(e) => {
                      setOrderCountry(e.target.value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {listOfCountry.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {submitButtonClicked && orderCountry === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="listOfImagePath"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Изображения
                  </label>

                  <ImageTableEdit
                    images={listOfImagePath}
                    orderId={params.orderId}
                  />
                </div>

                {/* Тип разбора */}
                <div>
                  <label
                    htmlFor="orderCountry"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Тип разбора*
                  </label>

                  <select
                    id="processTextValType"
                    name="processTextValType"
                    value={processTextValType}
                    onChange={(e) => {
                      setProcessTextValType(e.target.value);
                    }}
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={'position'}>Позиции</option>

                    <option value={'album'}>Альбомы</option>
                  </select>
                </div>

                {/* Текст для перевода в позиции */}
                <div>
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="processTextVal"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Текст для перевода в позиции
                    </label>

                    <HelpModal />
                  </div>
                  <textarea
                    name="processTextVal"
                    id="processTextVal"
                    value={processTextVal}
                    onChange={(e) => {
                      setProcessTextVal(e.target.value);
                    }}
                    className="mt-1 block w-full h-[200px] p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  <div className="mt-2 flex justify-between items-center">
                    <button
                      className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                      onClick={() => {
                        if (processTextValType === 'position') {
                          console.log('CLICKED');
                          processText(processTextVal);
                        } else if (processTextValType === 'album') {
                          processLines(processTextVal);
                        }
                      }}
                    >
                      Сгенерировать
                    </button>

                    <button
                      className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                      onClick={() => {
                        setProcessTextVal('');
                      }}
                    >
                      Очистить
                    </button>
                  </div>
                </div>

                <div className="">Количество позиций: {orderItems.length}</div>

                <div className="flex justify-between items-center">
                  <button
                    className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                    onClick={() => {
                      setHidePositions(!hidePositions);
                    }}
                  >
                    {hidePositions ? 'Показать позиции' : 'Скрыть позиции'}
                  </button>

                  {!hidePositions && (
                    <button
                      className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                      onClick={() => {
                        setOrderItems([
                          {
                            title: '',
                            itemPriceInConventionalUnits: '',
                            buyerNick: '@',
                            itemPriceInTenge: 0,
                            itemPriceInTengeExcluding: 0,
                            profit: 0,
                            paymentStatus: 'Не оплачено',
                          },
                        ]);
                      }}
                    >
                      Очистить все позиции
                    </button>
                  )}
                </div>

                {!hidePositions && (
                  <div>
                    <div className="space-y-4">
                      {orderItems.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="space-y-4 p-4 bg-gray-200 rounded-[10px]"
                        >
                          <div
                            className={`flex justify-between items-center text-red-500`}
                          >
                            <Icon
                              icon="streamline:delete-1-solid"
                              className="invisible"
                            />

                            <button
                              // disabled={!isEditableSuperAdmin}
                              onClick={() => {
                                handleRemoveOrderItem(index);
                              }}
                            >
                              <Icon icon="streamline:delete-1-solid" />
                            </button>
                          </div>

                          {/* Название карты */}
                          <div>
                            <label
                              htmlFor="title"
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Название позиции*
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              value={item.title}
                              // disabled={!isEditableSuperAdmin}
                              onChange={(e) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />

                            {submitButtonClicked && item.title === '' && (
                              <div className="mt-[5px] text-sm text-red-500">
                                Поле является обязательным для заполнения
                              </div>
                            )}
                          </div>

                          {/* Ник покупателя */}
                          <div>
                            <label
                              htmlFor="buyerNick"
                              // className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Ник покупателя*
                            </label>
                            <input
                              type="text"
                              name="buyerNick"
                              id="buyerNick"
                              value={item.buyerNick}
                              // disabled={!isEditableOrderTrackingAdmin}
                              onChange={(e) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />

                            {/* {submitButtonClicked && item.buyerNick === '@' && (
                              <div className="mt-[5px] text-sm text-red-500">
                                Поле является обязательным для заполнения
                              </div>
                            )} */}
                          </div>

                          {/* Цена каждой карты в у.е. */}
                          {ownedByUser && (
                            <div>
                              <label
                                htmlFor="itemPriceInConventionalUnits"
                                // className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                                className={`block text-sm font-medium text-green-500`}
                              >
                                Цена позиции в у.е.*
                              </label>
                              <input
                                type="number"
                                name="itemPriceInConventionalUnits"
                                id="itemPriceInConventionalUnits"
                                value={item.itemPriceInConventionalUnits}
                                onChange={(e) => {
                                  handleChangeOrderItem(e, index);
                                }}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />

                              {/* {ownedByUser &&
                                submitButtonClicked &&
                                (item.itemPriceInConventionalUnits === '' ||
                                  parseFloat(
                                    item.itemPriceInConventionalUnits
                                  ) === 0) && (
                                  <div className="mt-[5px] text-sm text-red-500">
                                    Поле является обязательным для заполнения
                                  </div>
                                )} */}
                            </div>
                          )}

                          {/* Цена в тенге по курсу */}
                          {ownedByUser && (
                            <div>
                              <label
                                htmlFor="itemPriceInTenge"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Цена в тенге по курсу (автоматически)
                              </label>
                              <input
                                type="text"
                                name="itemPriceInTenge"
                                id="itemPriceInTenge"
                                value={item.itemPriceInTenge}
                                disabled
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          )}

                          {/* Цена в тенге (без учетов) */}
                          {ownedByUser && (
                            <div>
                              <label
                                htmlFor="itemPriceInTengeExcluding"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Цена в тенге (без учетов) (автоматически)
                              </label>
                              <input
                                type="text"
                                name="itemPriceInTengeExcluding"
                                id="itemPriceInTengeExcluding"
                                value={item.itemPriceInTengeExcluding}
                                disabled
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          )}

                          {/* Прибыль */}
                          {ownedByUser && (
                            <div>
                              <label
                                htmlFor="profit"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Прибыль (автоматически)
                              </label>
                              <input
                                type="text"
                                name="profit"
                                id="profit"
                                value={item.profit}
                                disabled
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          )}

                          {/* Статус оплаты */}
                          <div>
                            <label
                              htmlFor="paymentStatus"
                              // className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                              className={`block text-sm font-medium text-green-500`}
                            >
                              Статус оплаты
                            </label>
                            <select
                              id="paymentStatus"
                              name="paymentStatus"
                              value={item.paymentStatus}
                              // disabled={!isEditableOrderTrackingAdmin}
                              onChange={(e: any) => {
                                handleChangeOrderItem(e, index);
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value={'Не оплачено'}>Не оплачено</option>
                              <option value={'Оплачено'}>Оплачено</option>
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-between items-center">
                      <button
                        //   disabled={!isEditableSuperAdmin}
                        //   className={`p-2 text-sm rounded text-white ${isEditableSuperAdmin ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-500'}`}
                        className={`p-2 text-sm rounded text-white bg-blue-500 hover:bg-blue-600`}
                        onClick={() => {
                          handleAddOrderItem();
                        }}
                      >
                        + Добавить карту в разбор
                      </button>
                    </div>
                  </div>
                )}

                {/* Учеты */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="surcharge"
                      //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                      className={`block text-sm font-medium text-green-500`}
                    >
                      Учеты*
                    </label>
                    <input
                      type="text"
                      name="surcharge"
                      id="surcharge"
                      value={surcharge}
                      //   disabled={!isEditableSuperAdmin}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (
                          /^\d*$/.test(value) &&
                          (value === '' || parseInt(value, 10) > 0)
                        ) {
                          setSurcharge(value);
                        }
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />

                    {/* {ownedByUser && submitButtonClicked && surcharge === '' && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле является обязательным для заполнения
                      </div>
                    )} */}
                  </div>
                )}

                {/* Итого по разбору */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="totalAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Итого по разбору (автоматически)
                    </label>
                    <input
                      type="text"
                      name="totalAmount"
                      id="totalAmount"
                      value={totalAmount}
                      disabled
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                {/* Зарплата */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="adminSalary"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Зарплата (автоматически)
                    </label>
                    <input
                      type="text"
                      name="adminSalary"
                      id="adminSalary"
                      value={adminSalary}
                      disabled
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                {/* Статус выплаты зарплаты */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="salaryPaymentStatus"
                      className={`block text-sm font-medium text-gray-700`}
                    >
                      Статус выплаты зарплаты
                    </label>
                    <select
                      id="salaryPaymentStatus"
                      name="salaryPaymentStatus"
                      value={salaryPaymentStatus || false}
                      disabled
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value={'Не оплачено'}>Не оплачено</option>
                      <option value={'Оплачено'}>Оплачено</option>
                    </select>
                  </div>
                )}

                {/* Трек-код посылки */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="trackingCode"
                      className="block text-sm font-medium text-green-500"
                    >
                      Трек-код посылки
                    </label>
                    <input
                      type="text"
                      name="trackingCode"
                      id="trackingCode"
                      value={trackingCode}
                      onChange={(e) => {
                        const value = e.target.value;

                        setTrackingCode(value);
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                {/* Статус разбора */}
                <div>
                  <label
                    htmlFor="orderStatus"
                    //   className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Статус разбора*
                  </label>
                  <select
                    id="orderStatus"
                    name="orderStatus"
                    value={orderStatus}
                    //   disabled={!isEditableOrderTrackingAdmin}
                    onChange={(e) => {
                      setOrderStatus(e.target.value);
                    }}
                    className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                  >
                    {listOfOrderStatus.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Ник продавца */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="sellerNick"
                      //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                      className={`block text-sm font-medium text-green-500`}
                    >
                      Ник продавца*
                    </label>
                    <input
                      type="text"
                      name="sellerNick"
                      id="sellerNick"
                      value={sellerNick}
                      //   disabled={!isEditableSuperAdmin}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (!value.startsWith('@')) {
                          setSellerNick('@' + value.replace(/@/g, '').trim());
                        } else {
                          setSellerNick(
                            value.replace(/@/g, '').trim().replace(/^/, '@')
                          );
                        }
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />

                    {/* {ownedByUser &&
                      submitButtonClicked &&
                      sellerNick === '@' && (
                        <div className="mt-[5px] text-sm text-red-500">
                          Поле является обязательным для заполнения
                        </div>
                      )} */}
                  </div>
                )}

                {/* Ссылка на пост с оплатой кор */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="paymentPostLinkKor"
                      //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                      className={`block text-sm font-medium text-green-500`}
                    >
                      Ссылка на пост с оплатой кор*
                    </label>
                    <input
                      type="text"
                      name="paymentPostLinkKor"
                      id="paymentPostLinkKor"
                      value={paymentPostLinkKor}
                      //   disabled={!isEditableSuperAdmin}
                      onChange={(e) => {
                        const value = e.target.value;

                        setPaymentPostLinkKor(value);
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />

                    {ownedByUser &&
                      submitButtonClicked &&
                      paymentPostLinkKor !== '' &&
                      !paymentPostLinkKor.startsWith('https://t.me/c/') && (
                        <div className="mt-[5px] text-sm text-red-500">
                          Поле заполнено неверно. Должно начинаться с
                          "https://t.me/c/"
                        </div>
                      )}
                  </div>
                )}

                {/* Ссылка на пост с оплатой */}
                <div>
                  <label
                    htmlFor="paymentPostLink"
                    //   className={`block text-sm font-medium ${isEditableOrderTrackingAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ссылка на пост с оплатой*
                  </label>
                  <input
                    type="text"
                    name="paymentPostLink"
                    id="paymentPostLink"
                    value={paymentPostLink}
                    //   disabled={!isEditableOrderTrackingAdmin}
                    onChange={(e) => {
                      const value = e.target.value;

                      setPaymentPostLink(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {shop === 'Meloddy Shop' &&
                    submitButtonClicked &&
                    paymentPostLink !== '' &&
                    !paymentPostLink.startsWith(
                      'https://t.me/meloddyshop/'
                    ) && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/meloddyshop/"
                      </div>
                    )}

                  {shop === 'Clouddy Shop' &&
                    submitButtonClicked &&
                    paymentPostLink !== '' &&
                    !paymentPostLink.startsWith(
                      'https://t.me/clouddyshop/'
                    ) && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/clouddyshop/"
                      </div>
                    )}
                </div>

                {/* Переплата по разбору */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="overpayment"
                      //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                      className={`block text-sm font-medium text-green-500`}
                    >
                      Переплата по разбору
                    </label>
                    <input
                      type="text"
                      name="overpayment"
                      id="overpayment"
                      value={overpayment}
                      //   disabled={!isEditableSuperAdmin}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (
                          /^\d*$/.test(value) &&
                          (value === '' || parseInt(value, 10) > 0)
                        ) {
                          setOverpayment(value);
                        }
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                {/* Комментарий */}
                {ownedByUser && (
                  <div>
                    <label
                      htmlFor="comment"
                      //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                      className={`block text-sm font-medium text-green-500`}
                    >
                      Комментарий
                    </label>
                    <textarea
                      name="comment"
                      id="comment"
                      value={comment}
                      //   disabled={!isEditableSuperAdmin}
                      onChange={(e) => {
                        const value = e.target.value;

                        setComment(value);
                      }}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                <button
                  className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                  onClick={handleSubmit}
                >
                  Редактировать разбор
                </button>
              </div>
            ) : role === 'admin' ? (
              <div className="space-y-4">
                {/* Номер разбора */}
                <div>
                  <label
                    htmlFor="orderNumeration"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Номер разбора*
                  </label>
                  <input
                    type="text"
                    ref={dateInputRef} // Привязываем ref к input
                    name="orderNumeration"
                    id="orderNumeration"
                    value={orderNumeration}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Дата разбора */}
                <div>
                  <label
                    htmlFor="date"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Дата разбора*
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={date || getCurrentDate}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Название группы */}
                <div>
                  <label
                    htmlFor="bandOrArtist"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Название группы*
                  </label>
                  <input
                    type="text"
                    name="bandOrArtist"
                    id="bandOrArtist"
                    value={bandOrArtist}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Страна разбора */}
                <div>
                  <label
                    htmlFor="orderCountry"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Страна разбора*
                  </label>
                  <select
                    id="orderCountry"
                    name="orderCountry"
                    value={orderCountry}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {listOfCountry.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="listOfImagePath"
                    //   className={`block text-sm font-medium ${isEditableSuperAdmin ? 'text-green-500' : 'text-gray-700'}`}
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Изображения
                  </label>

                  <ImageTableEditView images={listOfImagePath} />
                </div>

                <div className="space-y-4">
                  {orderItems.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="space-y-4 p-4 bg-gray-200 rounded-[10px]"
                    >
                      <div
                        className={`flex justify-between items-center text-red-500 invisible`}
                      >
                        <Icon
                          icon="streamline:delete-1-solid"
                          className="invisible"
                        />

                        <button>
                          <Icon icon="streamline:delete-1-solid" />
                        </button>
                      </div>

                      {/* Название карты */}
                      <div>
                        <label
                          htmlFor="title"
                          className={`block text-sm font-medium text-gray-700`}
                        >
                          Название позиции*
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          value={item.title}
                          disabled
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />

                        {/* {submitButtonClicked && item.title === '' && (
                          <div className="mt-[5px] text-sm text-red-500">
                            Поле является обязательным для заполнения
                          </div>
                        )} */}
                      </div>

                      {/* Ник покупателя */}
                      <div>
                        <label
                          htmlFor="buyerNick"
                          className={`block text-sm font-medium text-gray-700`}
                        >
                          Ник покупателя*
                        </label>
                        <input
                          type="text"
                          name="buyerNick"
                          id="buyerNick"
                          value={item.buyerNick}
                          disabled
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />

                        {/* {submitButtonClicked && item.buyerNick === '@' && (
                          <div className="mt-[5px] text-sm text-red-500">
                            Поле является обязательным для заполнения
                          </div>
                        )} */}
                      </div>

                      {/* Цена каждой карты в у.е. */}
                      <div>
                        <label
                          htmlFor="itemPriceInConventionalUnits"
                          className={`block text-sm font-medium text-green-500`}
                        >
                          Цена позиции в у.е.*
                        </label>
                        <input
                          type="number"
                          name="itemPriceInConventionalUnits"
                          id="itemPriceInConventionalUnits"
                          value={item.itemPriceInConventionalUnits}
                          onChange={(e) => {
                            handleChangeOrderItem(e, index);
                          }}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />

                        {submitButtonClicked &&
                          (item.itemPriceInConventionalUnits === '' ||
                            parseFloat(item.itemPriceInConventionalUnits) ===
                              0) && (
                            <div className="mt-[5px] text-sm text-red-500">
                              Поле является обязательным для заполнения
                            </div>
                          )}
                      </div>

                      {/* Цена в тенге по курсу */}
                      <div>
                        <label
                          htmlFor="itemPriceInTenge"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Цена в тенге по курсу (автоматически)
                        </label>
                        <input
                          type="text"
                          name="itemPriceInTenge"
                          id="itemPriceInTenge"
                          value={item.itemPriceInTenge}
                          disabled
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      {/* Цена в тенге (без учетов) */}
                      <div>
                        <label
                          htmlFor="itemPriceInTengeExcluding"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Цена в тенге (без учетов) (автоматически)
                        </label>
                        <input
                          type="text"
                          name="itemPriceInTengeExcluding"
                          id="itemPriceInTengeExcluding"
                          value={item.itemPriceInTengeExcluding}
                          disabled
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      {/* Прибыль */}
                      <div>
                        <label
                          htmlFor="profit"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Прибыль (автоматически)
                        </label>
                        <input
                          type="text"
                          name="profit"
                          id="profit"
                          value={item.profit}
                          disabled
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      {/* Статус оплаты */}
                      <div>
                        <label
                          htmlFor="paymentStatus"
                          className={`block text-sm font-medium text-green-500`}
                        >
                          Статус оплаты
                        </label>
                        <select
                          id="paymentStatus"
                          name="paymentStatus"
                          value={item.paymentStatus}
                          onChange={(e: any) => {
                            handleChangeOrderItem(e, index);
                          }}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                          <option value={'Не оплачено'}>Не оплачено</option>
                          <option value={'Оплачено'}>Оплачено</option>
                        </select>
                      </div>

                      {submitButtonClicked && item.paymentStatus === '' && (
                        <div className="mt-[5px] text-sm text-red-500">
                          Поле является обязательным для заполнения
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Учеты */}
                <div>
                  <label
                    htmlFor="surcharge"
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Учеты*
                  </label>
                  <input
                    type="text"
                    name="surcharge"
                    id="surcharge"
                    value={surcharge}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setSurcharge(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {/* {submitButtonClicked && surcharge === '' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )} */}
                </div>

                {/* Итого по разбору */}
                <div>
                  <label
                    htmlFor="totalAmount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Итого по разбору (автоматически)
                  </label>
                  <input
                    type="text"
                    name="totalAmount"
                    id="totalAmount"
                    value={totalAmount}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Зарплата */}
                <div>
                  <label
                    htmlFor="adminSalary"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Зарплата (автоматически)
                  </label>
                  <input
                    type="text"
                    name="adminSalary"
                    id="adminSalary"
                    value={adminSalary}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Статус выплаты зарплаты */}
                <div>
                  <label
                    htmlFor="salaryPaymentStatus"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Статус выплаты зарплаты
                  </label>
                  <select
                    id="salaryPaymentStatus"
                    name="salaryPaymentStatus"
                    value={salaryPaymentStatus || false}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={'Не оплачено'}>Не оплачено</option>
                    <option value={'Оплачено'}>Оплачено</option>
                  </select>
                </div>

                {/* Трек-код посылки */}
                <div>
                  <label
                    htmlFor="trackingCode"
                    className="block text-sm font-medium text-green-500"
                  >
                    Трек-код посылки
                  </label>
                  <input
                    type="text"
                    name="trackingCode"
                    id="trackingCode"
                    value={trackingCode}
                    onChange={(e) => {
                      const value = e.target.value;

                      setTrackingCode(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Статус разбора */}
                <div>
                  <label
                    htmlFor="orderStatus"
                    className={`block text-sm font-medium text-gray-700`}
                  >
                    Статус разбора*
                  </label>
                  <select
                    id="orderStatus"
                    name="orderStatus"
                    value={orderCountry}
                    disabled
                    className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                  >
                    {listOfOrderStatus.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Ник продавца */}
                <div>
                  <label
                    htmlFor="sellerNick"
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ник продавца*
                  </label>
                  <input
                    type="text"
                    name="sellerNick"
                    id="sellerNick"
                    value={sellerNick}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (!value.startsWith('@')) {
                        setSellerNick('@' + value.replace(/@/g, '').trim());
                      } else {
                        setSellerNick(
                          value.replace(/@/g, '').trim().replace(/^/, '@')
                        );
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked && sellerNick === '@' && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле является обязательным для заполнения
                    </div>
                  )}
                </div>

                {/* Ссылка на пост с оплатой кор */}
                <div>
                  <label
                    htmlFor="paymentPostLinkKor"
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Ссылка на пост с оплатой кор*
                  </label>
                  <input
                    type="text"
                    name="paymentPostLinkKor"
                    id="paymentPostLinkKor"
                    value={paymentPostLinkKor}
                    onChange={(e) => {
                      const value = e.target.value;

                      setPaymentPostLinkKor(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />

                  {submitButtonClicked &&
                    ((paymentPostLinkKor !== '' &&
                      !paymentPostLinkKor.startsWith('https://t.me/c/')) ||
                      paymentPostLinkKor === '') && (
                      <div className="mt-[5px] text-sm text-red-500">
                        Поле заполнено неверно. Должно начинаться с
                        "https://t.me/c/"
                      </div>
                    )}
                </div>

                {/* Переплата по разбору */}
                <div>
                  <label
                    htmlFor="overpayment"
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Переплата по разбору
                  </label>
                  <input
                    type="text"
                    name="overpayment"
                    id="overpayment"
                    value={overpayment}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        /^\d*$/.test(value) &&
                        (value === '' || parseInt(value, 10) > 0)
                      ) {
                        setOverpayment(value);
                      }
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Комментарий */}
                <div>
                  <label
                    htmlFor="comment"
                    className={`block text-sm font-medium text-green-500`}
                  >
                    Комментарий
                  </label>
                  <textarea
                    name="comment"
                    id="comment"
                    value={comment}
                    onChange={(e) => {
                      const value = e.target.value;

                      setComment(value);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <button
                  className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                  onClick={handleSubmit}
                >
                  Редактировать разбор
                </button>
              </div>
            ) : (
              <div className="">
                Системная ошибка: Обратитесь в тех. поддержку. Роль не найдена
              </div>
            )}
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
};

export default EditOrderPage;
