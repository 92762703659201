import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../LoadingScreen';
import { Links } from '../../links';
import * as utils from '../../utils';
import * as actions from '../../actions';
import * as constants from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaHome,
  FaList,
  FaShoppingCart,
  FaUser,
  FaBars,
  FaSearch,
} from 'react-icons/fa';

export async function logoutFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.logout,
    dispatch,
    `/auth/logout/`,
    'post',
    '',
    form,
    true
  )();
}

export async function profileInfoFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.profileInfo,
    dispatch,
    `/user/info/`,
    'get',
    '',
    form,
    true
  )();
}

export default function Header({
  isCatalogOpenPage = false,
  handleChangeIsCatalogOpenPage = null,
}: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { width, height } = utils.useWindowSize();

  const tokenCookie = utils.GetCookieValue('token');
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAuthMenuOpen, setIsAuthMenuOpen] = useState(false);

  const [role, setRole] = useState('');
  const [shopName, setShopName] = useState('');
  const [username, setUsername] = useState('');

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const logoutStore = useSelector((state: any) => state.logoutStore);

  const handleClickLogout = () => {
    let form: any = {};
    const tokenCookie = utils.GetCookieValue('token');
    if (tokenCookie) {
      const tokenRefresh = JSON.parse(
        utils.GetCookieValue('token') ?? '{}'
      ).refresh;
      form['refresh'] = `${tokenRefresh}`;
    }

    logoutFunc(dispatch, form);
    setIsAuthMenuOpen(!isAuthMenuOpen);
  };

  // useEffect(() => {
  //   // categoriesFunc(dispatch, {});

  // }, []);

  useEffect(() => {
    // console.log('profileInfoStore', profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      setRole(profileInfoStore.data.response.role);
      setShopName(profileInfoStore.data.response.shop);
      setUsername(profileInfoStore.data.response.username);
    }
  }, [profileInfoStore]);

  useEffect(() => {
    if (tokenCookie) {
      setLoggedIn(true);

      profileInfoFunc(dispatch, {});
    } else {
      setLoggedIn(false);
    }
  }, [tokenCookie]);

  useEffect(() => {
    // console.log("logoutStore", logoutStore);
    if (logoutStore && logoutStore.data) {
      utils.DeleteCookieValue('token');
      localStorage.setItem('cart', JSON.stringify([]));
      // LOCALSTORAGE: CreateProductData, PersonalInformationEditData, CreateBlockData, EditBlockData, AccountLaunchData
      // utils.LocalStorage.remove('DesignEditData');
      window.location.reload();
    }
  }, [logoutStore]);

  // if (profileInfoStore && profileInfoStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={profileInfoStore.load} />
  //     </div>
  //   );
  // }

  // if (categoriesStore && categoriesStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={categoriesStore.load} />
  //     </div>
  //   );
  // }

  return (
    <header className="font-manrope">
      <div>
        {/* Header */}
        <div
          // ref={headerRef}
          // className={`w-full bg-white shadow z-30 transition-transform duration-300 ${isSticky ? 'fixed top-0 left-0' : 'relative'}`}
          className={`w-full shadow z-20 transition-transform duration-300 fixed top-0 left-0 bg-white border-[#9086A2] border-b-[5px]`}
          // className={`w-full shadow bg-[#9086A2]`}
        >
          <div className="max-w-[1280px] mx-auto">
            <div className="flex justify-between items-center px-[20px] py-[10px]">
              {/* <div className="flex space-x-[5px] items-center cursor-pointer invisible">
                <Icon icon="ic:baseline-login" className="w-[30px] h-[30px]" />
                <span className="text-[16px] leading-[21px]">Войти</span>
              </div> */}

              {/* <img
                src="/images/logo.png"
                alt="logo"
                className="h-[73px] w-[136px] cursor-pointer bg-white"
                onClick={() => navigate(Links.home.url)}
              /> */}

              <img
                src="/images/logo_mini.png"
                alt="logo"
                className="h-[73px] w-[73px] cursor-pointer bg-white"
                onClick={() => navigate(Links.home.url)}
              />

              {loggedIn ? (
                <nav className="flex space-x-4 items-center">
                  <div className="relative">
                    <button
                      onClick={() => {
                        setIsAuthMenuOpen(!isAuthMenuOpen);
                      }}
                    >
                      <div className="flex space-x-[5px] items-center cursor-pointer text-[#9086A2]">
                        <Icon
                          icon="iconoir:profile-circle"
                          className="w-[30px] h-[30px]"
                        />
                        <span className="text-[16px] leading-[21px]">
                          <div>{username}</div>
                          <div>({shopName})</div>
                        </span>
                      </div>
                    </button>

                    {/* Выпадающее меню */}
                    {isAuthMenuOpen && (
                      <div
                        className="absolute w-[250px] bg-white rounded-lg shadow-lg border z-10"
                        // onMouseEnter={handleMouseEnter}
                        style={{
                          top: '120%',
                          left: '20%',
                          transform: 'translateX(-50%)',
                        }}
                      >
                        <ul className="py-2 text-sm text-[#9086A2] font-semibold">
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              navigate(Links.home.url);
                              setIsAuthMenuOpen(!isAuthMenuOpen);
                            }}
                          >
                            <div className="flex space-x-[10px] items-center">
                              <Icon
                                icon="material-symbols:two-pager-rounded"
                                className="w-[24px] h-[24px] text-[#9086A2]"
                              />
                              <div className="">Мои заказы</div>
                            </div>
                          </li>
                          {role === 'superadmin' && (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                navigate(Links.all_order_table.url);
                                setIsAuthMenuOpen(!isAuthMenuOpen);
                              }}
                            >
                              <div className="flex space-x-[10px] items-center">
                                <Icon
                                  icon="icon-park-outline:order"
                                  className="w-[24px] h-[24px] text-[#9086A2]"
                                />
                                <div className="">Таблица всех заказов</div>
                              </div>
                            </li>
                          )}
                          {['order_tracking_admin', 'superadmin'].includes(
                            role
                          ) && (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                navigate(Links.order_create.url);
                                setIsAuthMenuOpen(!isAuthMenuOpen);
                              }}
                            >
                              <div className="flex space-x-[10px] items-center">
                                <Icon
                                  icon="material-symbols:create-new-folder-outline"
                                  className="w-[24px] h-[24px] text-[#9086A2]"
                                />
                                <div className="">Создать разбор</div>
                              </div>
                            </li>
                          )}
                          {['order_tracking_admin', 'superadmin'].includes(
                            role
                          ) && (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                navigate(Links.order_tracking.url);
                                setIsAuthMenuOpen(!isAuthMenuOpen);
                              }}
                            >
                              <div className="flex space-x-[10px] items-center">
                                <Icon
                                  icon="iconamoon:delivery"
                                  className="w-[24px] h-[24px] text-[#9086A2]"
                                />
                                <div className="">Отслеживание заказов</div>
                              </div>
                            </li>
                          )}
                          {role === 'superadmin' && (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                navigate(Links.salary_table.url);
                                setIsAuthMenuOpen(!isAuthMenuOpen);
                              }}
                            >
                              <div className="flex space-x-[10px] items-center">
                                <Icon
                                  icon="solar:money-bag-outline"
                                  className="w-[24px] h-[24px] text-[#9086A2]"
                                />
                                <div className="">Таблица зарплат</div>
                              </div>
                            </li>
                          )}
                          {role === 'superadmin' && (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                navigate(Links.analytics.url);
                                setIsAuthMenuOpen(!isAuthMenuOpen);
                              }}
                            >
                              <div className="flex space-x-[10px] items-center">
                                <Icon
                                  icon="solar:graph-linear"
                                  className="w-[24px] h-[24px] text-[#9086A2]"
                                />
                                <div className="">Аналитика</div>
                              </div>
                            </li>
                          )}
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              navigate(Links.salary_payment.url);
                              setIsAuthMenuOpen(!isAuthMenuOpen);
                            }}
                          >
                            <div className="flex space-x-[10px] items-center">
                              <Icon
                                icon="fluent:money-16-regular"
                                className="w-[24px] h-[24px] text-[#9086A2]"
                              />
                              <div className="">История выплаты зарплат</div>
                            </div>
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              navigate(Links.password_change.url);
                              setIsAuthMenuOpen(!isAuthMenuOpen);
                            }}
                          >
                            <div className="flex space-x-[10px] items-center">
                              <Icon
                                icon="material-symbols:password"
                                className="w-[24px] h-[24px] text-[#9086A2]"
                              />
                              <div className="">Сменить пароль</div>
                            </div>
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={handleClickLogout}
                          >
                            <div className="flex space-x-[10px] items-center">
                              <Icon
                                icon="iconamoon:exit"
                                className="w-[24px] h-[24px] text-[#9086A2]"
                              />
                              <div className="">Выход</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </nav>
              ) : (
                <nav className="">
                  <button
                    onClick={() => {
                      navigate(Links.login.url);
                    }}
                  >
                    <div className="flex space-x-[5px] items-center cursor-pointer text-white">
                      <Icon
                        icon="ic:baseline-login"
                        className="w-[30px] h-[30px]"
                      />
                      <span className="text-[16px] leading-[21px]">Войти</span>
                    </div>
                  </button>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
