import React, { useEffect, useState } from 'react';
import Header from '../../components/ui/Header/HeaderHome';
import { Icon } from '@iconify/react';
import * as utils from '../../components/utils';
import * as constants from '../../components/constants';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Links } from '../../components/links';
import { useNavigate } from 'react-router-dom';

export function postLoginData(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = '',
  form: object | undefined = undefined
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};

      let response: any = undefined;

      switch (method.toLowerCase()) {
        case 'get': {
          response = await axios.get(
            `${constants.API}${url}${queryParams}`,
            // @ts-ignore
            { headers: config }
          );
          break;
        }
        case 'post': {
          response = await axios.post(
            `${constants.API}${url}`,
            form,
            // @ts-ignore
            { headers: config }
          );
          break;
        }
        default: {
          // todo raise exception
          // console.error("Unexpected method");
        }
      }
      if (response && response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        dispatch({
          type: constant.error,
          payload: response.statusText,
        });
      }
    } catch (error: any) {
      // console.log('error', error);

      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
      dispatch({
        type: constant.error,
        payload: error.response.data.error,
      });
      // console.error("error: ", error);
    }
  };
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const [showPassword, setShowPassword] = useState<any>(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');

  const sendLoginDataStore = useSelector(
    (state: any) => state.sendLoginDataStore
  );

  async function sendData() {
    await postLoginData(
      constants.sendLoginData,
      dispatch,
      `/auth/token/`,
      'post',
      '',
      {
        username: username,
        password: password,
      }
    )();
  }

  // useEffect(() => {
  //   dispatch({ type: constants.sendLoginData.reset });
  // }, []);

  useEffect(() => {
    // console.log('sendLoginDataStore', sendLoginDataStore);
    if (
      sendLoginDataStore &&
      (sendLoginDataStore.fail || sendLoginDataStore.error)
    ) {
      setLoggedIn(false);
      setError('Неверно указан логин или пароль');
    }

    if (sendLoginDataStore && sendLoginDataStore.data) {
      setError('');
      utils.SetCookieValue(
        'token',
        JSON.stringify(sendLoginDataStore.data.token)
        // 600,
      );

      dispatch({
        type: constants.token.success,
        payload: sendLoginDataStore.data.token,
      });
    }

    const tokenCookie = utils.GetCookieValue('token');
    if (tokenCookie) {
      setLoggedIn(true);
    }
  }, [sendLoginDataStore]);

  useEffect(() => {
    setError('');
  }, [password, username]);

  useEffect(() => {
    if (loggedIn) {
      // console.log('token in cookie');
      // navigate(Links.cart.url);
      navigate(Links.home.url);
    }
  }, [loggedIn]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendData();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Submitted Data:', formData);
    // Добавьте логику отправки данных на сервер

    let notEmptyBool = true;

    if (username === '') {
      notEmptyBool = false;
    }

    if (password === '') {
      notEmptyBool = false;
    }

    setSubmitButtonClicked(true);

    // console.log('notEmptyBool', notEmptyBool);

    if (notEmptyBool) {
      sendData();
    } else {
      setError('Заполните все обязательные поля');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 font-manrope">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center">
          <img
            src="/images/logo.png"
            alt="logo"
            className="h-[73px] w-[136px]"
          />
        </div>

        <div className="mt-[20px] space-y-4">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Логин
            </label>
            <input
              type="text"
              name="username"
              id="username"
              value={username}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                const value = e.target.value;

                setUsername(value);
              }}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Пароль
            </label>

            <div className="relative mt-2">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={password}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  const value = e.target.value;

                  setPassword(value);
                }}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />

              <button
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                <Icon
                  icon={`mdi:eye${showPassword ? '-off' : ''}`}
                  className="w-[20px] h-[20px]"
                />
              </button>
            </div>
          </div>

          {error !== '' && submitButtonClicked && (
            <div className="text-red-500 text-[13px] leading-[19px]">
              {error}
            </div>
          )}

          <button
            className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Войти
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
