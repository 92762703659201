export const DEBUG =
  window.location.host === '127.0.0.1:3000' ||
  window.location.host === 'localhost:3000';

// export const API = DEBUG
//   ? "http://127.0.0.1:8000/"
//   : "https://api.mensense.kz/";

export const API = 'https://api.meloddy.kz/api';
// export const API = 'http://127.0.0.1:8000/api';

export const mobileSize = 640;
export const tabletSize = 999; // 767

function constructorConstant(name: string) {
  return {
    load: `load_${name}`,
    success: `success_${name}`,
    fail: `fail_${name}`,
    error: `error_${name}`,
    reset: `reset_${name}`,
  };
}

export const token = constructorConstant('token');

export const sendLoginData = constructorConstant('sendLoginData');
export const logout = constructorConstant('logout');
export const profileInfo = constructorConstant('profileInfo');
export const usersOrder = constructorConstant('usersOrder');
export const salaryData = constructorConstant('salaryData');
export const salaryPayData = constructorConstant('salaryPayData');
export const createOrderData = constructorConstant('createOrderData');
export const sendCreateOrderData = constructorConstant('sendCreateOrderData');
export const editOrderData = constructorConstant('editOrderData');
export const sendEditOrderData = constructorConstant('sendEditOrderData');
export const trackingOrderData = constructorConstant('trackingOrderData');
export const allOrderTableData = constructorConstant('allOrderTableData');
export const changePassword = constructorConstant('changePassword');
export const salaryPaymentData = constructorConstant('salaryPaymentData');
export const analyticsData = constructorConstant('analyticsData');
export const sendAnalyticsData = constructorConstant('sendAnalyticsData');
export const deleteImage = constructorConstant('deleteImage');
export const addImage = constructorConstant('addImage');
