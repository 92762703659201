import React, { useState, useEffect, useRef } from 'react';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

export async function deleteImageFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.deleteImage,
    dispatch,
    `/user/delete/image/`,
    'post',
    '',
    form,
    true
  )();
}

export async function addImageFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.addImage,
    dispatch,
    `/user/add/image/`,
    'post',
    '',
    form,
    true
  )();
}

const ImageTable: React.FC<{ images: string[]; orderId: any }> = ({
  images,
  orderId,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const deleteImageStore = useSelector((state: any) => state.deleteImageStore);
  const addImageStore = useSelector((state: any) => state.addImageStore);

  useEffect(() => {
    // console.log('deleteImageStore', deleteImageStore);
    if (deleteImageStore && deleteImageStore.data) {
      window.location.reload();
    }
  }, [deleteImageStore]);

  useEffect(() => {
    // console.log('addImageStore', addImageStore);
    if (addImageStore && addImageStore.data) {
      window.location.reload();
    }
  }, [addImageStore]);

  const handleAddImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];

    if (file) {
      // Проверка допустимых расширений
      const allowedExtensions = ['jpeg', 'jpg', 'png'];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        alert('Допустимы только файлы с расширениями: jpeg, jpg, png.');
        return;
      }

      const formData = new FormData();
      formData.append('image', file);

      formData.append('orderId', orderId);

      addImageFunc(dispatch, formData);
    }
  };

  // Функция для перехода к следующему изображению
  const nextImage = () => {
    // if (selectedIndex !== null) {
    if (selectedIndex !== null && selectedIndex < images.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  // Функция для перехода к предыдущему изображению
  const prevImage = () => {
    // if (selectedIndex !== null) {
    if (selectedIndex !== null && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  // Обработчик событий для клавиш
  const handleKeyDown = (event: KeyboardEvent) => {
    if (selectedIndex !== null) {
      if (event.key === 'ArrowRight') {
        event.preventDefault(); // Отключаем действие прокрутки
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault(); // Отключаем действие прокрутки
        prevImage();
      } else if (event.key === 'Escape') {
        event.preventDefault(); // Предотвращаем любые лишние действия
        setSelectedIndex(null);
      }
    }
  };

  // Подключение и отключение обработчика клавиш
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);

  return (
    <div className="p-4">
      <div className="mb-[15px]">
        <button
          onClick={handleAddImageClick}
          className="bg-blue-500 text-[13px] text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Добавить картинку
        </button>

        {/* Скрытый input для выбора файла */}
        <input
          type="file"
          //   accept="image/*"
          accept=".jpeg, .jpg, .png"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
      </div>

      {/* Таблица с изображениями */}
      {images.length > 0 ? (
        <div className="grid grid-cols-3 gap-4">
          {images.map((image: any, imageIndex) => (
            <div key={imageIndex}>
              <img
                src={image.path}
                alt={`Image ${imageIndex + 1}`}
                className="max-w-[100px] w-full max-h-[100px] h-full object-cover cursor-pointer"
                onClick={() => setSelectedIndex(imageIndex)}
              />

              <button
                className="py-1 mt-[5px] max-w-[100px] w-full text-[13px] text-white bg-red-500 rounded-[5px]"
                onClick={() => {
                  deleteImageFunc(dispatch, { imageId: image.image_id });
                }}
              >
                Удалить
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="">Изображения по разбору не добавлены</div>
      )}

      {/* Модальное окно для изображения */}
      {selectedIndex !== null && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          //   onClick={() => setSelectedIndex(null)}
        >
          <div className="relative">
            <img
              src={
                //@ts-ignore
                images[selectedIndex].path
              }
              alt={`Selected ${selectedIndex}`}
              className="max-w-[700px] w-full max-h-[700px] h-full object-contain"
            />

            {/* Текущая позиция изображения */}
            <div className="absolute top-2 left-2 bg-black bg-opacity-50 rounded-full p-2 text-white text-[14px] select-none">
              {selectedIndex + 1}/{images.length}
            </div>

            {/* Кнопка закрытия */}
            <button
              className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[5px]"
              onClick={() => setSelectedIndex(null)}
            >
              ✕
            </button>
            {/* Кнопка "Назад" */}
            <button
              className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[7px] ${selectedIndex === 0 && 'invisible'}`}
              onClick={prevImage}
              disabled={selectedIndex === 0}
            >
              ←
            </button>
            {/* Кнопка "Вперед" */}
            <button
              className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[7px] ${selectedIndex === images.length - 1 && 'invisible'}`}
              onClick={nextImage}
              disabled={selectedIndex === images.length - 1}
            >
              →
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageTable;
