import React, { useEffect, useState } from 'react';

const LoadingModalScreen = ({ isLoad }: any) => {
  useEffect(() => {
    if (isLoad) {
      // Добавляем класс overflow-hidden при открытии модального окна
      document.body.classList.add('overflow-hidden');
    } else {
      // Удаляем класс при закрытии модального окна
      document.body.classList.remove('overflow-hidden');
    }

    // Чистка эффекта при размонтировании компонента
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isLoad]);

  return (
    <div>
      {/* Модальное окно */}
      {isLoad && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-green-500"></div>
        </div>
      )}
    </div>
  );
};

export default LoadingModalScreen;
