import React, { useState, useEffect } from 'react';

const ImageTable: React.FC<{ images: string[] }> = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  // Функция для перехода к следующему изображению
  const nextImage = () => {
    // if (selectedIndex !== null) {
    if (selectedIndex !== null && selectedIndex < images.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  // Функция для перехода к предыдущему изображению
  const prevImage = () => {
    // if (selectedIndex !== null) {
    if (selectedIndex !== null && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  // Обработчик событий для клавиш
  const handleKeyDown = (event: KeyboardEvent) => {
    if (selectedIndex !== null) {
      if (event.key === 'ArrowRight') {
        event.preventDefault(); // Отключаем действие прокрутки
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault(); // Отключаем действие прокрутки
        prevImage();
      } else if (event.key === 'Escape') {
        event.preventDefault(); // Предотвращаем любые лишние действия
        setSelectedIndex(null);
      }
    }
  };

  // Подключение и отключение обработчика клавиш
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);

  return (
    <div className="p-4">
      {/* Таблица с изображениями */}
      {images.length > 0 ? (
        <div className="grid grid-cols-3 gap-4">
          {images.map((image: any, imageIndex) => (
            <img
              key={imageIndex}
              src={image}
              alt={`Image ${imageIndex + 1}`}
              className="max-w-[100px] w-full max-h-[100px] h-full object-cover cursor-pointer"
              onClick={() => setSelectedIndex(imageIndex)}
            />
          ))}
        </div>
      ) : (
        <div className="">Изображения по разбору не добавлены</div>
      )}

      {/* Модальное окно для изображения */}
      {selectedIndex !== null && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          //   onClick={() => setSelectedIndex(null)}
        >
          <div className="relative">
            <img
              src={images[selectedIndex]}
              alt={`Selected ${selectedIndex}`}
              className="max-w-[700px] w-full max-h-[700px] h-full object-contain"
            />

            {/* Текущая позиция изображения */}
            <div className="absolute top-2 left-2 bg-black bg-opacity-50 rounded-full p-2 text-white text-[14px] select-none">
              {selectedIndex + 1}/{images.length}
            </div>

            {/* Кнопка закрытия */}
            <button
              className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[5px]"
              onClick={() => setSelectedIndex(null)}
            >
              ✕
            </button>
            {/* Кнопка "Назад" */}
            <button
              className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[7px] ${selectedIndex === 0 && 'invisible'}`}
              onClick={prevImage}
              disabled={selectedIndex === 0}
            >
              ←
            </button>
            {/* Кнопка "Вперед" */}
            <button
              className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full px-[10px] py-[7px] ${selectedIndex === images.length - 1 && 'invisible'}`}
              onClick={nextImage}
              disabled={selectedIndex === images.length - 1}
            >
              →
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageTable;
