import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from '../../components/ui/LoadingScreen';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ordersData from '../home/orders.json';
import Header from '../../components/ui/Header/HeaderHome';
import Footer from '../../components/ui/Footer/FooterHome';
import FeedbackIcon from '../../components/svg/feedback-icon';
import PromotionIcon from '../../components/svg/promotion-icon';
import ChatIcon from '../../components/svg/chat-icon';
import CouponIcon from '../../components/svg/coupon-icon';
import { Links } from '../../components/links';

export async function salaryDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.salaryData,
    dispatch,
    `/user/salary/data/`,
    'get',
    '',
    form,
    true
  )();
}

export async function salaryPayDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.salaryPayData,
    dispatch,
    `/user/salary/pay/`,
    'post',
    '',
    form,
    true
  )();
}

export default function Page() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = utils.useWindowSize();

  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>('');
  const [errorResponse, setErrorResponse] = useState<any>('');

  const salaryDataStore = useSelector((state: any) => state.salaryDataStore);
  const salaryPayDataStore = useSelector(
    (state: any) => state.salaryPayDataStore
  );
  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    // window.scrollTo(0, 0);

    // dispatch({ type: constants.addFeaturedProduct.reset });
    // dispatch({ type: constants.deleteFeaturedProduct.reset });
    salaryDataFunc(dispatch, {});

    // setOrders(ordersData);
  }, []);

  useEffect(() => {
    // console.log('salaryDataStore', salaryDataStore);
    if (salaryDataStore) {
      if (salaryDataStore.data) {
        setData(salaryDataStore.data.response);
      } else if (salaryDataStore.error) {
        setError(salaryDataStore.error);
      }
    }
  }, [salaryDataStore]);

  useEffect(() => {
    // console.log('salaryPayDataStore', salaryPayDataStore);
    if (salaryPayDataStore) {
      if (salaryPayDataStore.data) {
        window.location.reload();
      } else {
        setErrorResponse(salaryPayDataStore.error);
      }
    }
  }, [salaryPayDataStore]);

  const handleSendSalaryPayData = (index: number) => {
    salaryPayDataFunc(dispatch, {
      list_of_order_id: data[index].list_of_order_id,
      username: data[index].username,
      total_salary: data[index].total_salary,
    });
  };

  // Статусы заказа: Выкуплен, Отправлен к кор, Прибыл к кор, Прибыл в Кз, Отменен

  // if (hitProductsMainPageStore && hitProductsMainPageStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={hitProductsMainPageStore.load} />
  //     </div>
  //   );
  // }

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(salaryDataStore && salaryDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Перейти на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[120px] pb-[60px] px-4">
            <h1 className="text-xl font-semibold mb-4">
              Таблица зарплат администраторов
            </h1>

            {errorResponse ? (
              <div className="text-red-500">{errorResponse}</div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border border-gray-300 text-sm">
                  <thead>
                    <tr className="bg-gray-200 text-left">
                      {[
                        'Администратор',
                        'Роль',
                        'Процент прибыли',
                        'Общее количество разборов',
                        'Номера разборов',
                        'Сумма к оплате',
                        'Количество разборов со статусом "Выкуплен"',
                        // 'Переплаты',
                        '',
                      ].map((header) => (
                        <th
                          key={header}
                          className={`border border-gray-300 px-4 py-2`}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 ? (
                      <tr>
                        <td colSpan={5} className="">
                          Данные не найдены
                        </td>
                      </tr>
                    ) : (
                      data.map((item: any, index: number) => (
                        <tr key={index} className={`border border-gray-300`}>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.username}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.role}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.percentage_profit}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.count_of_order}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[250px] min-w-[250px]">
                            {item.list_of_order_id_str}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.total_salary} ₸
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.count_of_order_with_status_redeemed}
                          </td>
                          {/* <td className="border border-gray-300 px-4 py-2">
                            {item.overpayment}
                          </td> */}
                          <td className="border border-gray-300 px-4 py-2">
                            <button
                              onClick={() => {
                                handleSendSalaryPayData(index);
                              }}
                              disabled={item.total_salary === 0}
                              className={`p-2 ${item.total_salary !== 0 ? 'bg-blue-500' : 'bg-gray-400'} rounded-[5px] text-white`}
                            >
                              Оплатить
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
}
