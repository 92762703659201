import Header from '../../components/ui/Header/HeaderHome'; // ваш компонент Header
import Footer from '../../components/ui/Footer/FooterHome'; // ваш компонент Footer
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1); // Возвращаемся на предыдущую страницу
    } else {
      navigate('/'); // Переход на главную, если истории нет
    }
  };

  return (
    <div className="flex flex-col min-h-screen font-manrope">
      <Header />
      <main className="pt-[120px] flex-grow flex flex-col items-center justify-center">
        <img
          src="/images/error/404.png"
          alt="404"
          className="w-[350px] h-[350px]"
        />
        <div className="text-3xl font-bold text-gray-900">
          Страница не найдена
        </div>
        {/* <button
          onClick={handleGoBack}
          className="mt-[20px] px-[15px] py-[7px] text-[13px] leading-[19px] text-white bg-green-500 rounded hover:bg-green-600 transition"
        >
          Вернуться обратно
        </button> */}

        <button
          className="mt-[20px] p-2 bg-blue-500 rounded-[5px] text-white"
          onClick={handleGoBack}
        >
          Вернуться обратно
        </button>
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default NotFound;
