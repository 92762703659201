import React, { useEffect, useState } from 'react';
import Header from '../../components/ui/Header/HeaderHome';
import { Icon } from '@iconify/react';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/ui/LoadingScreen';
import LoadingModalScreen from '../../components/ui/LoadingModalScreen';
import HelpModal from '../../components/ui/HelpModal';
import { Links } from '../../components/links';

export async function createOrderDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.createOrderData,
    dispatch,
    `/user/create/order/data/`,
    'get',
    '',
    form,
    true
  )();
}

export async function sendCreateOrderDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.sendCreateOrderData,
    dispatch,
    `/user/create/order/`,
    'post',
    '',
    form,
    true
  )();
}

const CreateOrderPage: React.FC = () => {
  // Функция для получения текущей даты в формате YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<any>('');

  const [hidePositions, setHidePositions] = useState<any>(false);

  const [user, setUser] = useState<any>('');
  const [shop, setShop] = useState<any>('');
  const [orderNumeration, setOrderNumeration] = useState<any>('');
  const [date, setDate] = useState<any>(getCurrentDate());
  const [bandOrArtist, setBandOrArtist] = useState<any>('');
  const [surcharge, setSurcharge] = useState<any>('');
  const [totalAmount, setTotalAmount] = useState<any>('');
  const [adminSalary, setAdminSalary] = useState<any>('');
  const [trackingCode, setTrackingCode] = useState<any>('');
  const [orderStatus, setOrderStatus] = useState<any>('Выкуплен');
  const [orderCountry, setOrderCountry] = useState<any>('Корея');
  const [sellerNick, setSellerNick] = useState<any>('@');
  const [overpayment, setOverpayment] = useState<any>('');
  const [paymentPostLinkKor, setPaymentPostLinkKor] = useState<any>('');
  const [paymentPostLink, setPaymentPostLink] = useState<any>('');
  const [comment, setComment] = useState<any>('');
  const [processTextVal, setProcessTextVal] = useState<any>('');
  const [processTextValType, setProcessTextValType] = useState<any>('position');

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [adminPercentageProfit, setAdminPercentageProfit] = useState(null);
  const [convertFromUEtoTengeShop, setConvertFromUEtoTengeShop] =
    useState(null); // 5000
  const [convertFromUEtoTenge, setConvertFromUEtoTenge] = useState(null); // 2.56
  const [listOfCountry, setListOfCountry] = useState([]);
  const [listOfUsers, setListOfUsers] = useState([]);
  const [listOfShop, setListOfShop] = useState([]);
  const [orderItems, setOrderItems] = useState([
    {
      title: '',
      itemPriceInConventionalUnits: '',
      buyerNick: '@',
      itemPriceInTenge: 0,
      itemPriceInTengeExcluding: 0,
      profit: 0,
    },
  ]);

  const createOrderDataStore = useSelector(
    (state: any) => state.createOrderDataStore
  );

  const sendCreateOrderDataStore = useSelector(
    (state: any) => state.sendCreateOrderDataStore
  );

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    // console.log('createOrderDataStore', createOrderDataStore);
    if (createOrderDataStore) {
      if (createOrderDataStore.data) {
        const response = createOrderDataStore.data.response;

        setListOfShop(response.list_of_shop);
        setListOfCountry(response.list_of_order_country);
        setListOfUsers(response.list_of_users);
        // setAdminPercentageProfit(response.admin_percentage_profit);
        setConvertFromUEtoTenge(response.convert_from_won_to_tenge);
        setConvertFromUEtoTengeShop(response.convert_from_won_to_tenge_shop);
      } else if (createOrderDataStore.error) {
        setError(createOrderDataStore.error);
      }
    }
  }, [createOrderDataStore]);

  useEffect(() => {
    for (let i = 0; i < listOfUsers.length; i++) {
      //@ts-ignore
      if (listOfUsers[i].username === user) {
        //@ts-ignore
        setAdminPercentageProfit(listOfUsers[i].percentage_profit);
      }
    }
  }, [user]);

  useEffect(() => {
    createOrderDataFunc(dispatch, {});

    const localStorageData = utils.LocalStorage.get('createOrderData');

    if (localStorageData) {
      setOrderItems(localStorageData.items);

      if (localStorageData.data.user) {
        setUser(localStorageData.data.user);
      }

      if (localStorageData.data.hidePositions) {
        setHidePositions(localStorageData.data.hidePositions);
      }

      if (localStorageData.data.shop) {
        setShop(localStorageData.data.shop);
      }

      if (localStorageData.data.orderNumeration) {
        setOrderNumeration(localStorageData.data.orderNumeration);
      }

      if (localStorageData.data.date) {
        setDate(localStorageData.data.date);
      }

      if (localStorageData.data.processTextVal) {
        setProcessTextVal(localStorageData.data.processTextVal);
      }

      if (localStorageData.data.processTextValType) {
        setProcessTextValType(localStorageData.data.processTextValType);
      }

      if (localStorageData.data.bandOrArtist) {
        setBandOrArtist(localStorageData.data.bandOrArtist);
      }

      if (localStorageData.data.surcharge) {
        setSurcharge(localStorageData.data.surcharge);
      }

      //   if (localStorageData.data.totalAmount) {
      //     setTotalAmount(localStorageData.data.totalAmount);
      //   }

      //   if (localStorageData.data.adminSalary) {
      //     setAdminSalary(localStorageData.data.adminSalary);
      //   }

      if (localStorageData.data.trackingCode) {
        setTrackingCode(localStorageData.data.trackingCode);
      }

      if (localStorageData.data.orderCountry) {
        setOrderCountry(localStorageData.data.orderCountry);
      }

      if (localStorageData.data.sellerNick) {
        setSellerNick(localStorageData.data.sellerNick);
      }

      if (localStorageData.data.overpayment) {
        setOverpayment(localStorageData.data.overpayment);
      }

      if (localStorageData.data.paymentPostLinkKor) {
        setPaymentPostLinkKor(localStorageData.data.paymentPostLinkKor);
      }

      if (localStorageData.data.paymentPostLink) {
        setPaymentPostLink(localStorageData.data.paymentPostLink);
      }

      if (localStorageData.data.comment) {
        setComment(localStorageData.data.comment);
      }
    }
  }, []);

  useEffect(() => {
    utils.LocalStorage.set('createOrderData', {
      data: {
        user: user,
        hidePositions: hidePositions,
        shop: shop,
        orderNumeration: orderNumeration,
        date: date,
        bandOrArtist: bandOrArtist,
        orderCountry: orderCountry,
        processTextVal: processTextVal,
        processTextValType: processTextValType,
        surcharge: surcharge,
        // totalAmount: totalAmount,
        // adminSalary: adminSalary,
        trackingCode: trackingCode,
        sellerNick: sellerNick,
        overpayment: overpayment,
        paymentPostLinkKor: paymentPostLinkKor,
        paymentPostLink: paymentPostLink,
        comment: comment,
      },
      items: orderItems,
    });
  }, [
    orderItems,
    hidePositions,
    user,
    shop,
    orderNumeration,
    date,
    bandOrArtist,
    surcharge,
    orderCountry,
    processTextVal,
    processTextValType,
    // totalAmount,
    // adminSalary,
    trackingCode,
    sellerNick,
    overpayment,
    paymentPostLinkKor,
    paymentPostLink,
    comment,
  ]);

  useEffect(() => {
    // console.log('sendCreateOrderDataStore', sendCreateOrderDataStore);
    if (sendCreateOrderDataStore) {
      if (
        sendCreateOrderDataStore.data &&
        sendCreateOrderDataStore.data.response === 'OK'
      ) {
        utils.LocalStorage.remove('createOrderData');
        navigate(Links.home.url);
      } else if (sendCreateOrderDataStore.error) {
        alert(sendCreateOrderDataStore.error);
      }
    }
  }, [sendCreateOrderDataStore]);

  useEffect(() => {
    let adminSalaryTmp = 0;
    let totalAmountTmp = 0;

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].profit) {
        //@ts-ignore
        adminSalaryTmp += parseFloat(orderItems[i].profit);
      }
      if (orderItems[i].itemPriceInTengeExcluding) {
        //@ts-ignore
        totalAmountTmp += parseFloat(orderItems[i].itemPriceInTengeExcluding);
      }
    }

    if (surcharge) {
      totalAmountTmp += parseFloat(surcharge);
    }

    if (adminPercentageProfit) {
      adminSalaryTmp *= adminPercentageProfit / 100;
    }

    if (overpayment) {
      adminSalaryTmp += parseFloat(overpayment) / 2;
    }

    adminSalaryTmp = parseFloat(adminSalaryTmp.toFixed(2));
    totalAmountTmp = parseFloat(totalAmountTmp.toFixed(2));

    setAdminSalary(adminSalaryTmp);
    setTotalAmount(totalAmountTmp);

    // setOrderItems(orderItemsTmp);
  }, [orderItems, surcharge, adminPercentageProfit, overpayment]);

  const handleChangeOrderItem = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    let orderItemsTmp = structuredClone(orderItems);

    if (name === 'itemPriceInConventionalUnits') {
      // Разрешить пустое значение для очистки
      if (value === '') {
        orderItemsTmp[index][name] = '';
        //@ts-ignore
        orderItemsTmp[index].itemPriceInTenge = 0;
        orderItemsTmp[index].itemPriceInTengeExcluding = 0;
        orderItemsTmp[index].profit = 0;
      } else {
        const valueTmp = parseFloat(value);
        if (!isNaN(valueTmp) && valueTmp >= 0) {
          //@ts-ignore
          orderItemsTmp[index][name] = valueTmp;

          //@ts-ignore
          orderItemsTmp[index].itemPriceInTengeExcluding =
            //@ts-ignore
            parseFloat((valueTmp * convertFromUEtoTengeShop).toFixed(2));

          //@ts-ignore
          orderItemsTmp[index].itemPriceInTenge =
            //@ts-ignore
            parseFloat(((valueTmp * 10000) / convertFromUEtoTenge).toFixed(2));

          //@ts-ignore
          orderItemsTmp[index].profit = parseFloat(
            //@ts-ignore
            (
              orderItemsTmp[index].itemPriceInTengeExcluding -
              //@ts-ignore
              orderItemsTmp[index].itemPriceInTenge
            ).toFixed(2)
          );
        }
      }
    } else if (name === 'title') {
      //@ts-ignore
      orderItemsTmp[index][name] = value;
    } else if (name === 'buyerNick') {
      if (!value.startsWith('@')) {
        orderItemsTmp[index][name] = '@' + value.replace(/@/g, '').trim();
      } else {
        orderItemsTmp[index][name] = value
          .replace(/@/g, '')
          .trim()
          .replace(/^/, '@');
      }
    }
    setOrderItems(orderItemsTmp);
  };

  const handleAddOrderItem = () => {
    let orderItemsTmp = structuredClone(orderItems);

    if (
      orderItemsTmp[orderItemsTmp.length - 1].title.trim() &&
      orderItemsTmp[orderItemsTmp.length - 1].itemPriceInConventionalUnits
        .toString()
        .trim() &&
      parseFloat(
        orderItemsTmp[orderItemsTmp.length - 1].itemPriceInConventionalUnits
      ) !== 0
    ) {
      orderItemsTmp.push({
        title: '',
        itemPriceInConventionalUnits: '',
        buyerNick: '@',
        itemPriceInTenge: 0,
        itemPriceInTengeExcluding: 0,
        profit: 0,
      });

      setOrderItems(orderItemsTmp);
    }
  };

  const handleRemoveOrderItem = (indexToRemove: number) => {
    let orderItemsTmp = structuredClone(orderItems);

    if (orderItemsTmp.length > 1) {
      orderItemsTmp.splice(indexToRemove, 1);

      setOrderItems(orderItemsTmp);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Submitted Data:', formData);
    // Добавьте логику отправки данных на сервер

    let notEmptyBool = true;

    if (user === '') {
      notEmptyBool = false;
    }

    if (shop === '') {
      notEmptyBool = false;
    }

    if (orderNumeration === '' || orderNumeration === 0) {
      notEmptyBool = false;
    }

    if (date === '' || date === 0) {
      notEmptyBool = false;
    }

    if (bandOrArtist === '' || bandOrArtist === 0) {
      notEmptyBool = false;
    }

    if (orderCountry === '' || orderCountry === 0) {
      notEmptyBool = false;
    }

    if (
      paymentPostLinkKor !== '' &&
      !paymentPostLinkKor.startsWith('https://t.me/c/')
    ) {
      notEmptyBool = false;
    }

    if (
      shop === 'Meloddy Shop' &&
      !paymentPostLink.startsWith('https://t.me/meloddyshop/')
    ) {
      notEmptyBool = false;
    }

    if (
      shop === 'Clouddy Shop' &&
      !paymentPostLink.startsWith('https://t.me/clouddyshop/')
    ) {
      notEmptyBool = false;
    }

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].title === '' || parseFloat(orderItems[i].title) === 0) {
        notEmptyBool = false;
      }

      if (
        orderItems[i].buyerNick === '@' ||
        parseFloat(orderItems[i].buyerNick) === 0
      ) {
        notEmptyBool = false;
      }
    }

    setSubmitButtonClicked(true);

    if (notEmptyBool) {
      const sendDataDict = {
        username: user,
        shop: shop,
        orderNumeration: orderNumeration,
        date: date,
        bandOrArtist: bandOrArtist,
        surcharge: surcharge || 0,
        totalAmount: totalAmount || 0,
        adminSalary: adminSalary || 0,
        trackingCode: trackingCode,
        orderCountry: orderCountry,
        orderStatus: orderStatus,
        sellerNick: sellerNick,
        overpayment: overpayment || 0,
        paymentPostLinkKor: paymentPostLinkKor,
        paymentPostLink: paymentPostLink,
        comment: comment,
        adminPercentageProfit: adminPercentageProfit,
        convertFromUEtoTenge: convertFromUEtoTenge,
        convertFromUEtoTengeShop: convertFromUEtoTengeShop,
        orderItems: orderItems,
      };

      sendCreateOrderDataFunc(dispatch, sendDataDict);
    }
  };

  const processText = (text: string) => {
    // Разбиваем текст на строки
    const lines = text.split('\n');

    // Список результатов
    const resultList: { items: string; contact: string }[] = [];

    lines.forEach((line) => {
      // Убираем нумерацию
      const withoutNumber = line.replace(/^\d+\.\s*/, ''); // Убираем "1. ", "2. " и т.д.

      // Проверяем формат строки
      if (withoutNumber.includes(' - ')) {
        const parts = withoutNumber.split(' - ');
        const [items, rest] = parts;

        // Извлекаем контакт (после "@") и удаляем возможные эмодзи
        const match = rest.match(/@[\w\d_]+/); // Поиск слова с "@" (без эмодзи)
        const contact = match ? match[0] : '@';

        resultList.push({
          items: items.trim(),
          contact: contact.trim(),
        });
      }
    });

    let orderItemsTmp = [];

    for (let i = 0; i < resultList.length; i++) {
      orderItemsTmp.push({
        title: resultList[i].items,
        itemPriceInConventionalUnits: '',
        buyerNick: resultList[i].contact,
        itemPriceInTenge: 0,
        itemPriceInTengeExcluding: 0,
        profit: 0,
      });
    }

    setOrderItems(orderItemsTmp);
    // console.log('orderItemsTmp', orderItemsTmp);
  };

  const processLines = (text: string) => {
    // Разделяем текст на блоки по "->"
    const blocks = text
      .split('->')
      .map((block) => block.trim())
      .filter(Boolean);

    const result: {
      title: string;
      value: string;
      description: string;
      contact: string;
    }[] = [];

    blocks.forEach((block) => {
      const [rawTitle, ...lines] = block.split('\n').map((line) => line.trim());
      const titleMatch = rawTitle.match(/(.+)-\s*([\d.]+)/);
      const title = titleMatch ? titleMatch[1].trim() : rawTitle.trim();
      const value = titleMatch ? titleMatch[2].trim() : '';

      const contacts = lines
        .filter((line) => line.includes('@'))
        .map((line) => {
          const contactMatch = line.match(/(@\w+)/); // Ищем контакт с "@"
          const contact = contactMatch ? contactMatch[0] : '@';

          const descriptionTmp = line.replace(contact, '').trim(); // Всё, что после контакта
          const descriptionList = descriptionTmp.split('  ');
          let description = '';
          if (descriptionList.length > 1) {
            description = descriptionList[descriptionList.length - 1];
          }
          return { contact, description };
        });

      if (contacts.length > 0) {
        contacts.forEach(({ contact, description }) => {
          result.push({
            title: `${title} ${description}`,
            value,
            description,
            contact,
          });
        });
      } else {
        result.push({ title, value: '', description: '', contact: '@' });
      }
    });

    let orderItemsTmp = [];

    for (let i = 0; i < result.length; i++) {
      if (result[i].value === '') {
        orderItemsTmp.push({
          title: `Альбом (${result[i].title}${result[i].description && ` ${result[i].description}`})`,
          itemPriceInConventionalUnits: '',
          buyerNick: result[i].contact,
          itemPriceInTenge: 0,
          itemPriceInTengeExcluding: 0,
          profit: 0,
        });
      } else {
        let itemPriceInTengeExcludingTmp = parseFloat(
          //@ts-ignore
          (parseFloat(result[i].value) * convertFromUEtoTengeShop).toFixed(2)
        );

        let itemPriceInTengeTmp = parseFloat(
          (
            (parseFloat(result[i].value) * 10000) /
            //@ts-ignore
            convertFromUEtoTenge
          ).toFixed(2)
        );

        let profitTmp = parseFloat(
          (itemPriceInTengeExcludingTmp - itemPriceInTengeTmp).toFixed(2)
        );

        orderItemsTmp.push({
          title: `Альбом (${result[i].title}${result[i].description && ` ${result[i].description}`})`,
          itemPriceInConventionalUnits: parseFloat(result[i].value),
          buyerNick: result[i].contact,
          itemPriceInTenge: itemPriceInTengeTmp,
          itemPriceInTengeExcluding: itemPriceInTengeExcludingTmp,
          profit: profitTmp,
        });
      }
    }
    //@ts-ignore
    setOrderItems(orderItemsTmp);
  };

  //   if (createOrderDataStore && createOrderDataStore.load) {
  //     return (
  //       <div>
  //         <LoadingScreen isLoading={createOrderDataStore.load} />
  //       </div>
  //     );
  //   }

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(createOrderDataStore && createOrderDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Перейти на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-xl mx-auto pt-[120px] pb-[60px] px-4 bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <div className="text-xl font-semibold">Создать разбор</div>

              <LoadingModalScreen isLoad={sendCreateOrderDataStore.load} />

              <button
                className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                onClick={() => {
                  setUser('');
                  setShop('');
                  setOrderNumeration('');
                  setDate(getCurrentDate());
                  setBandOrArtist('');
                  setProcessTextVal('');
                  setSurcharge('');
                  setSurcharge('');
                  setTotalAmount('');
                  setAdminSalary('');
                  setTrackingCode('');
                  setSellerNick('@');
                  setOverpayment('');
                  setPaymentPostLinkKor('');
                  setPaymentPostLink('');
                  setComment('');
                  setSubmitButtonClicked(false);

                  setOrderItems([
                    {
                      title: '',
                      itemPriceInConventionalUnits: '',
                      buyerNick: '@',
                      itemPriceInTenge: 0,
                      itemPriceInTengeExcluding: 0,
                      profit: 0,
                    },
                  ]);
                }}
              >
                Очистить
              </button>
            </div>
            {/* <form onSubmit={handleSubmit} className="space-y-4"> */}
            <div className="space-y-4">
              {/* Магазин */}
              <div>
                <label
                  htmlFor="shop"
                  className="block text-sm font-medium text-gray-700"
                >
                  Магазин*
                </label>
                <select
                  id="shop"
                  name="shop"
                  value={shop}
                  onChange={(e) => {
                    setShop(e.target.value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={''} disabled>
                    --Выберите магазин--
                  </option>
                  {listOfShop.map((item: any, index: number) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {submitButtonClicked && shop === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Администратор */}
              <div>
                <label
                  htmlFor="user"
                  className="block text-sm font-medium text-gray-700"
                >
                  Администратор*
                </label>
                <select
                  id="user"
                  name="user"
                  value={user}
                  onChange={(e: any) => {
                    setUser(e.target.value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={''} disabled>
                    --Выберите администратора--
                  </option>
                  {listOfUsers.map((item: any, index: number) => (
                    <option key={index} value={item.username}>
                      {item.username} ({item.shop_name})
                    </option>
                  ))}
                </select>

                {submitButtonClicked && user === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Номер заказа */}
              <div>
                <label
                  htmlFor="orderNumeration"
                  className="block text-sm font-medium text-gray-700"
                >
                  Номер заказа*
                </label>
                <input
                  type="text"
                  name="orderNumeration"
                  id="orderNumeration"
                  value={orderNumeration}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (
                      /^\d*$/.test(value) &&
                      (value === '' || parseInt(value, 10) > 0)
                    ) {
                      setOrderNumeration(value);
                    }
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                {submitButtonClicked && orderNumeration === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Дата разбора */}
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Дата разбора*
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  value={date}
                  onChange={(e) => {
                    const value = e.target.value;

                    setDate(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                {submitButtonClicked && date === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Название группы */}
              <div>
                <label
                  htmlFor="bandOrArtist"
                  className="block text-sm font-medium text-gray-700"
                >
                  Название группы*
                </label>
                <input
                  type="text"
                  name="bandOrArtist"
                  id="bandOrArtist"
                  value={bandOrArtist}
                  onChange={(e) => {
                    const value = e.target.value;

                    setBandOrArtist(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                {submitButtonClicked && bandOrArtist === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Страна разбора */}
              <div>
                <label
                  htmlFor="orderCountry"
                  className="block text-sm font-medium text-gray-700"
                >
                  Страна разбора*
                </label>
                <select
                  id="orderCountry"
                  name="orderCountry"
                  value={orderCountry}
                  onChange={(e) => {
                    setOrderCountry(e.target.value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  {listOfCountry.map((item: any, index: number) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {submitButtonClicked && orderCountry === '' && (
                  <div className="mt-[5px] text-sm text-red-500">
                    Поле является обязательным для заполнения
                  </div>
                )}
              </div>

              {/* Тип разбора */}
              <div>
                <label
                  htmlFor="orderCountry"
                  className="block text-sm font-medium text-gray-700"
                >
                  Тип разбора*
                </label>

                <select
                  id="processTextValType"
                  name="processTextValType"
                  value={processTextValType}
                  onChange={(e) => {
                    setProcessTextValType(e.target.value);
                  }}
                  className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={'position'}>Позиции</option>

                  <option value={'album'}>Альбомы</option>
                </select>
              </div>

              {/* Текст для перевода в позиции */}
              <div>
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="processTextVal"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Текст для перевода в позиции
                  </label>

                  <HelpModal />
                </div>
                <textarea
                  name="processTextVal"
                  id="processTextVal"
                  value={processTextVal}
                  onChange={(e) => {
                    setProcessTextVal(e.target.value);
                  }}
                  className="mt-1 block w-full h-[200px] p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                <div className="mt-2 flex justify-between items-center">
                  <button
                    className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                    onClick={() => {
                      if (processTextValType === 'position') {
                        console.log('CLICKED');
                        processText(processTextVal);
                      } else if (processTextValType === 'album') {
                        processLines(processTextVal);
                      }
                    }}
                  >
                    Сгенерировать
                  </button>

                  <button
                    className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                    onClick={() => {
                      setProcessTextVal('');
                    }}
                  >
                    Очистить
                  </button>
                </div>
              </div>

              <div className="">Количество позиций: {orderItems.length}</div>

              <div className="flex justify-between items-center">
                <button
                  className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                  onClick={() => {
                    setHidePositions(!hidePositions);
                  }}
                >
                  {hidePositions ? 'Показать позиции' : 'Скрыть позиции'}
                </button>

                {!hidePositions && (
                  <button
                    className="p-2 bg-red-500 text-sm rounded text-white hover:bg-red-600"
                    onClick={() => {
                      setOrderItems([
                        {
                          title: '',
                          itemPriceInConventionalUnits: '',
                          buyerNick: '@',
                          itemPriceInTenge: 0,
                          itemPriceInTengeExcluding: 0,
                          profit: 0,
                        },
                      ]);
                    }}
                  >
                    Очистить все позиции
                  </button>
                )}
              </div>

              {!hidePositions && (
                <div>
                  <div className="space-y-4">
                    {orderItems.map((item: any, index: number) => (
                      <div
                        key={index}
                        className="space-y-4 p-4 bg-gray-200 rounded-[10px]"
                      >
                        <div
                          className={`flex justify-between items-center text-red-500`}
                        >
                          <Icon
                            icon="streamline:delete-1-solid"
                            className="invisible"
                          />

                          <button
                            onClick={() => {
                              handleRemoveOrderItem(index);
                            }}
                          >
                            <Icon icon="streamline:delete-1-solid" />
                          </button>
                        </div>

                        {/* Название карты */}
                        <div>
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Название позиции*
                          </label>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={item.title}
                            onChange={(e) => {
                              handleChangeOrderItem(e, index);
                            }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />

                          {submitButtonClicked && item.title === '' && (
                            <div className="mt-[5px] text-sm text-red-500">
                              Поле является обязательным для заполнения
                            </div>
                          )}
                        </div>

                        {/* Ник покупателя */}
                        <div>
                          <label
                            htmlFor="buyerNick"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ник покупателя*
                          </label>
                          <input
                            type="text"
                            name="buyerNick"
                            id="buyerNick"
                            value={item.buyerNick}
                            onChange={(e) => {
                              handleChangeOrderItem(e, index);
                            }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />

                          {/* {submitButtonClicked && item.buyerNick === '@' && (
                            <div className="mt-[5px] text-sm text-red-500">
                              Поле является обязательным для заполнения
                            </div>
                          )} */}
                        </div>

                        {/* Цена каждой карты в у.е. */}
                        <div>
                          <label
                            htmlFor="itemPriceInConventionalUnits"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Цена позиции в у.е.
                          </label>
                          <input
                            type="number"
                            name="itemPriceInConventionalUnits"
                            id="itemPriceInConventionalUnits"
                            value={item.itemPriceInConventionalUnits}
                            onChange={(e) => {
                              handleChangeOrderItem(e, index);
                            }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>

                        {/* Цена в тенге по курсу */}
                        <div>
                          <label
                            htmlFor="itemPriceInTenge"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Цена в тенге по курсу (автоматически)
                          </label>
                          <input
                            type="text"
                            name="itemPriceInTenge"
                            id="itemPriceInTenge"
                            value={item.itemPriceInTenge}
                            disabled
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>

                        {/* Цена в тенге (без учетов) */}
                        <div>
                          <label
                            htmlFor="itemPriceInTengeExcluding"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Цена в тенге (без учетов) (автоматически)
                          </label>
                          <input
                            type="text"
                            name="itemPriceInTengeExcluding"
                            id="itemPriceInTengeExcluding"
                            value={item.itemPriceInTengeExcluding}
                            disabled
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>

                        {/* Прибыль */}
                        <div>
                          <label
                            htmlFor="profit"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Прибыль (автоматически)
                          </label>
                          <input
                            type="text"
                            name="profit"
                            id="profit"
                            value={item.profit}
                            disabled
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex justify-between items-center">
                    <button
                      className="p-2 bg-blue-500 text-sm rounded text-white hover:bg-blue-600"
                      onClick={handleAddOrderItem}
                    >
                      + Добавить карту в разбор
                    </button>
                  </div>
                </div>
              )}

              {/* Учеты */}
              <div>
                <label
                  htmlFor="surcharge"
                  className="block text-sm font-medium text-gray-700"
                >
                  Учеты
                </label>
                <input
                  type="text"
                  name="surcharge"
                  id="surcharge"
                  value={surcharge}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (
                      /^\d*$/.test(value) &&
                      (value === '' || parseInt(value, 10) >= 0)
                    ) {
                      setSurcharge(value);
                    }
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Итого по разбору */}
              <div>
                <label
                  htmlFor="totalAmount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Итого по разбору (автоматически)
                </label>
                <input
                  type="text"
                  name="totalAmount"
                  id="totalAmount"
                  value={totalAmount}
                  disabled
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Зарплата */}
              <div>
                <label
                  htmlFor="adminSalary"
                  className="block text-sm font-medium text-gray-700"
                >
                  Зарплата (автоматически)
                </label>
                <input
                  type="text"
                  name="adminSalary"
                  id="adminSalary"
                  value={adminSalary}
                  disabled
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Трек-код посылки */}
              <div>
                <label
                  htmlFor="trackingCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Трек-код посылки
                </label>
                <input
                  type="text"
                  name="trackingCode"
                  id="trackingCode"
                  value={trackingCode}
                  onChange={(e) => {
                    const value = e.target.value;

                    setTrackingCode(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Статус разбора */}
              <div>
                <label
                  htmlFor="orderStatus"
                  className="block text-sm font-medium text-gray-700"
                >
                  Статус разбора*
                </label>
                <input
                  type="text"
                  name="orderStatus"
                  id="orderStatus"
                  value={orderStatus}
                  disabled
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Ник продавца */}
              <div>
                <label
                  htmlFor="sellerNick"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ник продавца
                </label>
                <input
                  type="text"
                  name="sellerNick"
                  id="sellerNick"
                  value={sellerNick}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (!value.startsWith('@')) {
                      setSellerNick('@' + value.replace(/@/g, '').trim());
                    } else {
                      setSellerNick(
                        value.replace(/@/g, '').trim().replace(/^/, '@')
                      );
                    }
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Ссылка на пост с оплатой кор */}
              <div>
                <label
                  htmlFor="paymentPostLinkKor"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ссылка на пост с оплатой кор
                </label>
                <input
                  type="text"
                  name="paymentPostLinkKor"
                  id="paymentPostLinkKor"
                  value={paymentPostLinkKor}
                  onChange={(e) => {
                    const value = e.target.value;

                    setPaymentPostLinkKor(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                {submitButtonClicked &&
                  paymentPostLinkKor !== '' &&
                  !paymentPostLinkKor.startsWith('https://t.me/c/') && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле заполнено неверно. Должно начинаться с
                      "https://t.me/c/"
                    </div>
                  )}
              </div>

              {/* Ссылка на пост с оплатой */}
              <div>
                <label
                  htmlFor="paymentPostLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ссылка на пост с оплатой*
                </label>
                <input
                  type="text"
                  name="paymentPostLink"
                  id="paymentPostLink"
                  value={paymentPostLink}
                  onChange={(e) => {
                    const value = e.target.value;

                    setPaymentPostLink(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />

                {shop === 'Meloddy Shop' &&
                  submitButtonClicked &&
                  !paymentPostLink.startsWith('https://t.me/meloddyshop/') && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле заполнено неверно. Должно начинаться с
                      "https://t.me/meloddyshop/"
                    </div>
                  )}

                {shop === 'Clouddy Shop' &&
                  submitButtonClicked &&
                  !paymentPostLink.startsWith('https://t.me/clouddyshop/') && (
                    <div className="mt-[5px] text-sm text-red-500">
                      Поле заполнено неверно. Должно начинаться с
                      "https://t.me/clouddyshop/"
                    </div>
                  )}
              </div>

              {/* Переплата по разбору */}
              <div>
                <label
                  htmlFor="overpayment"
                  className="block text-sm font-medium text-gray-700"
                >
                  Переплата по разбору
                </label>
                <input
                  type="text"
                  name="overpayment"
                  id="overpayment"
                  value={overpayment}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (
                      /^\d*$/.test(value) &&
                      (value === '' || parseInt(value, 10) >= 0)
                    ) {
                      setOverpayment(value);
                    }
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Комментарий */}
              <div>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium text-gray-700"
                >
                  Комментарий
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  value={comment}
                  onChange={(e) => {
                    const value = e.target.value;

                    setComment(value);
                  }}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <button
                className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Создать разбор
              </button>
            </div>
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
};

export default CreateOrderPage;
