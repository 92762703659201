import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from '../../components/ui/LoadingScreen';
import * as actions from '../../components/actions';
import * as constants from '../../components/constants';
import * as utils from '../../components/utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ordersData from '../home/orders.json';
import Header from '../../components/ui/Header/HeaderHome';
import Footer from '../../components/ui/Footer/FooterHome';
import FeedbackIcon from '../../components/svg/feedback-icon';
import PromotionIcon from '../../components/svg/promotion-icon';
import ChatIcon from '../../components/svg/chat-icon';
import CouponIcon from '../../components/svg/coupon-icon';
import { Links } from '../../components/links';

export async function salaryPaymentDataFunc(dispatch: any, form: object) {
  actions.constructorAction(
    constants.salaryPaymentData,
    dispatch,
    `/user/salary/payment/data/`,
    'get',
    '',
    form,
    true
  )();
}

export default function Page() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = utils.useWindowSize();

  const [data, setData] = useState<any>([]);
  const [role, setRole] = useState<any>('');

  const [error, setError] = useState<any>('');

  const salaryPaymentDataStore = useSelector(
    (state: any) => state.salaryPaymentDataStore
  );

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    // window.scrollTo(0, 0);

    // dispatch({ type: constants.addFeaturedProduct.reset });
    // dispatch({ type: constants.deleteFeaturedProduct.reset });
    salaryPaymentDataFunc(dispatch, {});

    // setOrders(ordersData);
  }, []);

  useEffect(() => {
    // console.log('profileInfoStore', profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      setRole(profileInfoStore.data.response.role);
    }
  }, [profileInfoStore]);

  useEffect(() => {
    // console.log('salaryPaymentDataStore', salaryPaymentDataStore);
    if (salaryPaymentDataStore) {
      if (salaryPaymentDataStore.data) {
        setData(salaryPaymentDataStore.data.response);
      } else if (salaryPaymentDataStore.error) {
        setError(salaryPaymentDataStore.error);
      }
    }
  }, [salaryPaymentDataStore]);

  // Статусы заказа: Выкуплен, Отправлен к кор, Прибыл к кор, Прибыл в Кз, Отменен

  // if (hitProductsMainPageStore && hitProductsMainPageStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={hitProductsMainPageStore.load} />
  //     </div>
  //   );
  // }

  return (
    <utils.AuthGuard>
      <div className="font-manrope">
        <Header />

        {(salaryPaymentDataStore && salaryPaymentDataStore.load) ||
        (profileInfoStore && profileInfoStore.load) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-[3px] border-b-[3px] border-[#9086A2]"></div>
          </div>
        ) : error ? (
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex justify-center">
              <div className="flex flex-col items-center">
                <div>Ошибка: {error}</div>
                <button
                  className="mt-[40px] p-2 bg-blue-500 rounded-[5px] text-white"
                  onClick={() => {
                    navigate(Links.home.url);
                  }}
                >
                  Перейти на главную
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[120px] pb-[60px] px-4">
            <div className="text-xl font-semibold mb-4">
              Таблица выплаты зарплат
            </div>

            {error ? (
              <div className="text-red-500">{error}</div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border border-gray-300 text-sm">
                  <thead>
                    <tr className="bg-gray-200 text-left">
                      {role === 'superadmin' && (
                        <th className={`border border-gray-300 px-4 py-2`}>
                          Администратор
                        </th>
                      )}
                      {[
                        'Общее количество разборов',
                        'Номера разборов',
                        'Сумма выплаты',
                        'Дата выплаты',
                      ].map((header) => (
                        <th
                          key={header}
                          className={`border border-gray-300 px-4 py-2`}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 ? (
                      <tr>
                        <td colSpan={5} className="">
                          Данные не найдены
                        </td>
                      </tr>
                    ) : (
                      data.map((item: any, index: number) => (
                        <tr key={index} className={`border border-gray-300`}>
                          {role === 'superadmin' && (
                            <td className="border border-gray-300 px-4 py-2">
                              {item.username}
                            </td>
                          )}
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.order_count}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[250px] min-w-[250px]">
                            {item.list_of_order_id_str}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[200px] min-w-[200px]">
                            {item.payment_amount} ₸
                          </td>
                          <td className="border border-gray-300 px-4 py-2 w-[250px] min-w-[250px]">
                            {item.created_at}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </utils.AuthGuard>
  );
}
