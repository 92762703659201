import * as constants from './constants';
import axios from 'axios';
import * as utils from './utils';

export function refreshToken() {
  return async function func() {
    try {
      let form: any = {};
      const tokenCookie = utils.GetCookieValue('token');
      if (tokenCookie) {
        const tokenRefresh = JSON.parse(
          utils.GetCookieValue('token') ?? '{}'
        ).refresh;
        form['refresh'] = `${tokenRefresh}`;
      }

      const response = await axios.post(
        `${constants.API}/token/refresh/`,
        form
        // @ts-ignore
        // config,
      );
      // console.log("response Ref", response);
      if (response && response.data) {
        utils.SetCookieValue(
          'token',
          JSON.stringify(response.data)
          // 600
        );

        console.log('token refreshed');
      } else {
        // console.log("error in response", response.statusText);
      }
    } catch (error: any) {
      console.log('Refresh error', error);
      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
    }
  };
}

export function constructorAction(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = '',
  form: object | undefined = undefined,
  auth: boolean = true
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};
      if (auth) {
        const tokenCookie = utils.GetCookieValue('token');
        if (tokenCookie) {
          const tokenAccess = JSON.parse(
            utils.GetCookieValue('token') ?? '{}'
          ).access;
          config['Authorization'] = `Bearer ${tokenAccess}`;
        } else {
          config['Authorization'] = '';
        }
      }

      let response: any = undefined;
      let errorMain: any = undefined;

      switch (method.toLowerCase()) {
        case 'get': {
          try {
            response = await axios.get(
              `${constants.API}${url}${queryParams}`,
              // @ts-ignore
              { headers: config }
            );
          } catch (err: any) {
            // console.log('err', err);
            // if (err.response.data.code === "token_not_valid") {

            if (auth && err.response.status === 401) {
              await refreshToken()();
              const tokenCookie = utils.GetCookieValue('token');
              if (tokenCookie) {
                const tokenAccess = JSON.parse(
                  utils.GetCookieValue('token') ?? '{}'
                ).access;
                config['Authorization'] = `Bearer ${tokenAccess}`;
              } else {
                config['Authorization'] = '';
              }

              response = await axios.get(
                `${constants.API}${url}${queryParams}`,
                // @ts-ignore
                { headers: config }
              );
            } else {
              errorMain = err;
            }
          }
          break;
        }
        case 'post': {
          try {
            response = await axios.post(
              `${constants.API}${url}`,
              form,
              // @ts-ignore
              { headers: config }
            );
          } catch (err: any) {
            // console.log('err', err);
            // if (err.response.data.code === "token_not_valid") {

            if (auth && err.response.status === 401) {
              await refreshToken()();
              const tokenCookie = utils.GetCookieValue('token');
              if (tokenCookie) {
                const tokenAccess = JSON.parse(
                  utils.GetCookieValue('token') ?? '{}'
                ).access;
                config['Authorization'] = `Bearer ${tokenAccess}`;
              } else {
                config['Authorization'] = '';
              }
              response = await axios.post(
                `${constants.API}${url}`,
                form,
                // @ts-ignore
                { headers: config }
              );
            } else {
              errorMain = err;
            }
          }
          break;
        }
        default: {
          // todo raise exception
          console.error('Unexpected method');
        }
      }

      if (response && response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        console.log('errorMain', errorMain);
        dispatch({
          type: constant.error,
          payload: errorMain?.response.data.error || 'No data received',
        });
      }
    } catch (error: any) {
      console.error('error: ', error);
      if (error.response && error.response.data) {
        dispatch({
          type: constant.fail,
          payload: error.response.data.error || 'An error occurred',
        });
      } else {
        dispatch({
          type: constant.fail,
          payload: error.toString(),
        });
      }
    }
  };
}

let isRefreshing = false;
let refreshSubscribers: Array<Function> = [];

function subscribeTokenRefresh(callback: Function) {
  refreshSubscribers.push(callback);
}

function onRrefreshed(token: string) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

export function refreshToken2() {
  return async function func() {
    if (isRefreshing) {
      // Возвращаем Promise, который будет разрешен, когда токен обновится
      return new Promise((resolve) => {
        subscribeTokenRefresh(resolve);
      });
    }

    isRefreshing = true;

    try {
      let form: any = {};
      const tokenCookie = utils.GetCookieValue('token');
      if (tokenCookie) {
        const tokenRefresh = JSON.parse(
          utils.GetCookieValue('token') ?? '{}'
        ).refresh;
        form['refresh'] = `${tokenRefresh}`;
      }

      const response = await axios.post(
        `${constants.API}/token/refresh/`,
        form
      );

      if (response && response.data) {
        utils.SetCookieValue('token', JSON.stringify(response.data));
        isRefreshing = false;
        onRrefreshed(response.data.access); // Сообщаем всем ожидающим запросам, что токен обновлен
        console.log('token refreshed');
        return response.data.access;
      }
    } catch (error: any) {
      console.log('Refresh error', error);
      isRefreshing = false;
      throw error;
    }
  };
}

export function constructorAction2(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = '',
  form: object | undefined = undefined,
  auth: boolean = true
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};

      if (auth) {
        const tokenCookie = utils.GetCookieValue('token');
        if (tokenCookie) {
          const tokenAccess = JSON.parse(
            utils.GetCookieValue('token') ?? '{}'
          ).access;
          config['Authorization'] = `Bearer ${tokenAccess}`;
        } else {
          config['Authorization'] = '';
        }
      }

      let response: any = undefined;
      let errorMain: any = undefined;

      switch (method.toLowerCase()) {
        case 'get': {
          try {
            response = await axios.get(`${constants.API}${url}${queryParams}`, {
              headers: config,
            });
          } catch (err: any) {
            if (auth && err.response.status === 401) {
              // Ожидаем обновления токена
              const newToken = await refreshToken()();
              config['Authorization'] = `Bearer ${newToken}`;
              response = await axios.get(
                `${constants.API}${url}${queryParams}`,
                { headers: config }
              );
            } else {
              errorMain = err;
            }
          }
          break;
        }
        case 'post': {
          try {
            response = await axios.post(`${constants.API}${url}`, form, {
              headers: config,
            });
          } catch (err: any) {
            if (auth && err.response.status === 401) {
              const newToken = await refreshToken()();
              config['Authorization'] = `Bearer ${newToken}`;
              response = await axios.post(`${constants.API}${url}`, form, {
                headers: config,
              });
            } else {
              errorMain = err;
            }
          }
          break;
        }
        default: {
          console.error('Unexpected method');
        }
      }

      if (response && response.data) {
        dispatch({ type: constant.success, payload: response.data });
      } else {
        console.log('errorMain', errorMain);
        dispatch({
          type: constant.error,
          payload: errorMain?.response?.data?.error || 'No data received',
        });
      }
    } catch (error: any) {
      console.error('error: ', error);
      dispatch({
        type: constant.fail,
        payload: error.response?.data?.error || error.toString(),
      });
    }
  };
}

// TODO Logout: нужно очистить кукисы и reset нужных переменных из redux и сделать reboot страницы

//
// export function getAllTodos() {
//   return constructorAction({
//     url: `https://jsonplaceholder.typicode.com/todos/`,
//     constant: constants.listTodos,
//   });
// }
//
// export function constructorAction(
//   props = {
//     // @ts-ignore
//     url,
//     constant: {
//       // @ts-ignore
//       load: string,
//       // @ts-ignore
//       success: string,
//       // @ts-ignore
//       fail: string,
//       // @ts-ignore
//       error: string,
//       // @ts-ignore
//       reset: string,
//     },
//   }
// ) {
//   return async function (dispatch: any) {
//     try {
//       // TODO load
//       dispatch({ type: props.constant.load });
//       const response = await axios.get(props.url); // todo откуда берём данные
//       if (response.status === 200 || response.status === 201) {
//         // TODO success
//         dispatch({
//           type: props.constant.success, // todo куда ложим успешные данные
//           payload: response.data,
//         });
//       } else {
//         // TODO error
//         dispatch({
//           type: props.constant.error,
//           payload: response.statusText,
//         });
//       }
//     } catch (error) {
//       console.log("error: ", error);
//       // TODO fail
//       dispatch({
//         type: props.constant.fail,
//         // @ts-ignore
//         payload: error.toString(),
//       });
//     }
//   };
// }
