import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './components/store';
import Router from './components/router';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'tailwindcss/tailwind.css';
//@ts-ignore
import Modal from 'react-modal';

import { LanguageChange } from './components/utils';
import './i18n';

const container = document.getElementById('root')!;
const root = createRoot(container);

Modal.setAppElement('#root');

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <Suspense fallback={<div>Loading (language)...</div>}> */}
    {/* <LanguageChange> */}
    <Router />
    {/* </LanguageChange> */}
    {/* </Suspense> */}
  </Provider>
  // </React.StrictMode>
);
